import React, { useState, useEffect } from 'react';
import '.././App.css';
import axios from 'axios';
import { Card, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Button } from 'react-bootstrap'
import AddContact from './AddContact'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CustomerContact() {

    const { customer_id } = useParams();
    const classes = useStyles();

    const [show, setShow] = useState(false);

    const [contact, setContact] = useState(null);

    let [update, setUpdate] = useState(false)

    const onUpdateHandler = () => {
        setUpdate(!update)
    }

    const modalVisibility = () => {
        setShow(!show)
    }

    const customerdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/customer/read.php').then((response) => {
                console.log(response);
                const contact_data = (response.data.records);
                const filter_contact = contact_data.filter(contactNew => {
                    return contactNew.customer_id === customer_id
                });
                setContact(filter_contact);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customerdata();
    }, [])

    const customersData = async () => {
        try {
            await axios.get('https://api.athind.com/api/customer/read.php').then((response) => {
                console.log(response);
                const contact_data = (response.data.records);
                const filter_contact = contact_data.filter(contactNew => {
                    return contactNew.customer_id === customer_id
                });
                setContact(filter_contact);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customersData();
    }, [update])


    const deleteContact = async (contact_id) => {
        await axios.delete('https://api.athind.com/api/customer/contact_delete.php/', { data: { contact_id: contact_id } }).then((response) => {
            setContact(contact.filter((val) => {
                return val.contact_id !== contact_id;
            }));
            toast.success("Contact is Deleted!");
        });
    };
    return (
        <div>
            {/* <div className="addnewp">Customer Information</div> */}
            <div className="container cus">
                {/* <Link className="btn btn-primary butn" to="/pages/customer-profile/AddCustomer">Add Customer</Link> */}

                <Button variant="primary" onClick={modalVisibility}>
                    Add Customer Contact
                </Button>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddContact
                        updateHandler={onUpdateHandler}
                        modal_visibility={modalVisibility}
                    />
                </Modal.Body>
            </Modal>
            <div className="addnewp">Customer Contact Information</div>
            {/* <div className="btn btn-primary" style={{ marginTop: '13px'}} onClick={getCustomers}>Load Customers</div> */}
            <ToastContainer />
            <Card className="cus-card">
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                {/* <th>customer ID</th> */}
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Payment Term</th>
                                <th>Credit Limit</th>
                                <th>Payment Days</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {} */}
                            {contact ? contact.map((val) => {
                                return <tr key={val.contact_id}>
                                    <td>{val.contact_id}</td>
                                    {/* <td>{val.customer_id}</td> */}
                                    <td>{val.contact_name}</td>
                                    <td>{val.contact_email}</td>
                                    <td>{val.contact_phone}</td>
                                    <td>{val.contact_dept}</td>
                                    <td>{val.contact_desig}</td>
                                    <td>{val.contact_pay_term}</td>
                                    <td>{val.contact_credit_limit}</td>
                                    <td>{val.contact_pay_days}</td>
                                    <td className="cust-bttn">
                                        <Link className="btn btn-primary eddl" to={`/pages/customer-profile/EditContact/${val.contact_id}`}><EditOutlinedIcon/></Link>
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteContact(val.contact_id); }}><DeleteForeverOutlinedIcon/></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default CustomerContact
