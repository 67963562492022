import React, { useState } from 'react';
import '.././App.css';
import Axios from 'axios';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import { MenuItem, Select, IconButton, InputLabel  } from '@material-ui/core';
import { Collapse, Accordion, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Stitched() {

  /* start image preview */
  const [imgPreview, setImgPreview] = useState(null);
  const [error, setError] = useState(false);

  const handleImageChange = (e) => {

    setError(false);

    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      }
      reader.readAsDataURL(selected);
    } else {
      setError(true);
    }
  };
  /* end image preview */

  /* start collapse */
  const [open, setOpen] = useState(false);
  /* end collapse */


  const [title, setTitle] = useState("");
  const [type, setType] = useState("stitched");
  const [gsm, setGsm] = useState("");
  const [price, setPrice] = useState("");
  const [bagColor, setBagColor] = useState("");
  const [patteren, setPatteren] = useState("");
  const [width, setWidth] = useState("");
  const [lenght, setLenght] = useState("");
  const [gusset, setGusset] = useState("");
  const [topfold, setTopfold] = useState("");
  const [loopwidth, setLoopWidth] = useState("");
  const [looplength, setLoopLength] = useState("");
  const [loopColor, setLoopColor] = useState("");
  const [Qbags, setQbags] = useState("");
  const [piping, setPiping] = useState("");
  // const [fabricprice, setFabricprice] = useState("");
  const [wastage, setWastage] = useState("");
  const [wastagerecovery, setWastagerecovery] = useState("");
  const [cmt, setCmt] = useState("");
  const [printing, setPrinting] = useState("");
  const [packing, setPacking] = useState("");
  const [overheads, setOverheads] = useState("");
  const [profit, setProfit] = useState("");
  const [saletax, setSaletax] = useState("0");
  const [incometax, setIncometax] = useState("0");
  const [commission, setCommission] = useState("0");

  // const [succ, setSucc] = useState(false);

  function SubmitButton() {
    if (title && type && gsm && price && bagColor && width && lenght && gusset && topfold && loopwidth && looplength && loopColor && Qbags && piping && wastage
      && wastagerecovery && cmt && printing && packing && overheads && profit && saletax && incometax && commission) {

      return <button className="btn addpbtn btn-block" onClick={addProduct}>add new product</button>
    } else {
      return <button className="btn addpbtn btn-block" disabled>add new product</button>
    };
  };

  const addProduct = () => {
    Axios.post('https://api.athind.com/api/product/create.php', {
      pro_title: title,
      pro_type: type,
      pro_img: imgPreview,
      gsm: gsm,
      price: price,
      bag_color: bagColor,
      patteren: patteren,
      printing_color: printColr,
      width: width,
      lenght: lenght,
      gusset: gusset,
      topfold: topfold,
      loop_width: loopwidth,
      loop_length: looplength,
      loop_color: loopColor,
      quantity_bags: Qbags,
      piping: piping,
      // fabricprice: fabricprice,
      wastage: wastage,
      wastagerecovery: wastagerecovery,
      cmt: cmt,
      printing: printing,
      packing: packing,
      overheads: overheads,
      profit: profit,
      saletax: saletax,
      incometax: incometax,
      commission: commission
    }).then(() => {
      // setSucc(true);
      toast.success("Product is Added!");
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    setTitle("");
    setGsm("");
    setPrice("");
    setBagColor("");
    setPatteren("");
    setPrintColr("");
    setWidth("");
    setLenght("");
    setGusset("");
    setTopfold("");
    setLoopWidth("");
    setLoopLength("");
    setLoopColor("");
    setQbags("");
    setPiping("");
    setWastage("");
    setWastagerecovery("");
    setCmt("");
    setPrinting("");
    setPacking("");
    setOverheads("");
    setProfit("");
    setSaletax("");
    setIncometax("");
    setCommission("");
  }

  const [printColr, setPrintColr] = useState('');
  const [opens, setOpens] = useState(false);

  const handleChange = (e) => {
    setPrintColr(e.target.value);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleOpen = () => {
    setOpens(true);
  };




  return (
    <div>
      <div className="addnewp">Add New Stitched Product</div>
      <div className="container">
        <div className="ptitle">title</div>
        <div className="row">
          <div className="col-sm-3">
            <input type="text" className="form-control tfield" value={title} onChange={(e) => { setTitle(e.target.value); }} />
          </div>
          <div>
            <input type="text" hidden className="form-control tfield" value="stitched" onChange={(e) => { setType(e.target.value); }} />
          </div>
        </div>
        {/* end product title */}
        {/* start product image */}
        <div className="ptitle">upload image</div>
        <div className="row">
          <div className="col-sm-3">
            {error && <p className="errorMsg">File is not Supported</p>}
            <div className="img-holder"
              style={{ background: imgPreview ? `url("${imgPreview}") no-repeat center/cover` : "" }}
            >
              {!imgPreview && (
                <AddPhotoAlternateOutlinedIcon className="addimg" />
              )}
            </div>
            <input type="file" id="file" onChange={handleImageChange} />
            <label htmlFor="file">

              {!imgPreview && (
                <div className="btn expandbtn">Choose an Image</div>
              )}
            </label>
            <div>
              {imgPreview && (
                <div className="btn expandbtn" onClick={() => setImgPreview(null)}>Remove Image</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* start product title */}
      {/* end product image */}
      {/* start Particulars */}
      <div className="particulars">
        <div className="particular">Particulars</div>
        <div className="container form-div">
          <div className="expandbutton">
            {!open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandMoreOutlinedIcon className="expand" />expand all tabs</button>}
          </div>
          <div className="closebutton">
            {open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandLessOutlinedIcon className="expand" />close all tabs</button>}
          </div>

          {/* start basic row */}
          <div className="basic-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Basics <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              {/* {succ && <p className="errorproMsg">Product is inserted</p>} */}
              <ToastContainer />
              <Collapse in={open}>
                <Accordion.Collapse eventKey="1">
                <div  id="collapse">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">gsm</div>
                      <input type="number" className="form-control tfield" value={gsm} onChange={(e) => { setGsm(e.target.value); }} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Bag Color</div>
                      <input type="text" className="form-control tfield" value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Pattern</div>
                      <input type="text" className="form-control tfield" value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Printing Color</div>
                      {/* <InputLabel id="demo-controlled-open-select-label">Pattern Color</InputLabel> */}
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={opens}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={printColr}
                        onChange={handleChange}
                        className="tfield ptrnclr"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1 color">1 color</MenuItem>
                        <MenuItem value="2 color">2 color</MenuItem>
                        <MenuItem value="3 color">3 color</MenuItem>
                        <MenuItem value="4 color">4 color</MenuItem>
                      </Select>
                    </div>
                  </div>
                  </div>
                </Accordion.Collapse>
              </Collapse>
            </Accordion>
          </div>
          {/* end basic row */}

          {/* start size row */}
          <div className="size-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Size <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              <Collapse in={open}>
                <Accordion.Collapse eventKey="1">
                  <div id="collapse">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="partitle">width <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={width} onChange={(e) => { setWidth(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">length <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={lenght} onChange={(e) => { setLenght(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">gusset <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={gusset} onChange={(e) => { setGusset(e.target.value); }} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="partitle">top fold <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={topfold} onChange={(e) => { setTopfold(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">piping <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={piping} onChange={(e) => { setPiping(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">Quantity Bags <span className="psubtitle">(Pcs)</span></div>
                        <input type="number" className="form-control tfield" value={Qbags} onChange={(e) => { setQbags(e.target.value); }} />
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Collapse>
            </Accordion>
          </div>
          {/* end size row */}

          {/* start loop row */}
          <div className="size-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Loop <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              <Collapse in={open}>
                <Accordion.Collapse eventKey="1">
                  <div id="collapse">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="partitle">Loop Width <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={loopwidth} onChange={(e) => { setLoopWidth(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">Loop Length <span className="psubtitle">(Inches)</span></div>
                        <input type="number" className="form-control tfield" value={looplength} onChange={(e) => { setLoopLength(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">Loop Color</div>
                        <input type="text" className="form-control tfield" value={loopColor} onChange={(e) => { setLoopColor(e.target.value); }} />
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Collapse>
            </Accordion>
          </div>
          {/* end loop row */}
          

          {/* start price row */}
          <div className="price-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Pricing <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              <Collapse in={open}>
                <Accordion.Collapse eventKey="1">
                  <div id="collapse">
                    <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle"> Fabric Price <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" value={price} onChange={(e) => { setPrice(e.target.value); }} />
                    </div>
                      <div className="col-sm-4">
                        <div className="partitle">wastage <span className="psubtitle">(Per KG)</span></div>
                        <input type="number" className="form-control tfield" value={wastage} onChange={(e) => { setWastage(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">wastage recovery <span className="psubtitle">(Per KG)</span></div>
                        <input type="number" className="form-control tfield" value={wastagerecovery} onChange={(e) => { setWastagerecovery(e.target.value); }} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-4">
                        <div className="partitle">cmt <span className="psubtitle">(Per KG)</span></div>
                        <input type="number" className="form-control tfield" value={cmt} onChange={(e) => { setCmt(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">printing <span className="psubtitle">(Per KG)</span></div>
                        <input type="number" className="form-control tfield" value={printing} onChange={(e) => { setPrinting(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">packing <span className="psubtitle">(Per KG)</span></div>
                        <input type="number" className="form-control tfield" value={packing} onChange={(e) => { setPacking(e.target.value); }} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-4">
                        <div className="partitle">over heads <span className="psubtitle">(%)</span></div>
                        <input type="number" className="form-control tfield" value={overheads} onChange={(e) => { setOverheads(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">profit <span className="psubtitle">(%)</span></div>
                        <input type="number" className="form-control tfield" value={profit} onChange={(e) => { setProfit(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">sale tax <span className="psubtitle">(%)</span></div>
                        <input type="number" className="form-control tfield" value={saletax} onChange={(e) => { setSaletax(e.target.value); }} />
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-4">
                        <div className="partitle">income tax <span className="psubtitle">(%)</span></div>
                        <input type="number" className="form-control tfield" value={incometax} onChange={(e) => { setIncometax(e.target.value); }} />
                      </div>
                      <div className="col-sm-4">
                        <div className="partitle">commission <span className="psubtitle">(%)</span></div>
                        <input type="number" className="form-control tfield" value={commission} onChange={(e) => { setCommission(e.target.value); }} />
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Collapse>
            </Accordion>
          </div>
          {/* end price row */}
          {/* <button className="btn addpbtn btn-block" onClick={addProduct}>add new product</button> */}
          <SubmitButton />
          <button className="btn addpbtn btn-block" onClick={handleSubmit}>Reset Fields</button>
        </div>
      </div>
      {/* end Particulars */}
    </div>
  )
}

export default Stitched;
