import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'

export default class Logout extends Component {
    constructor(props) {
        super(props)
        localStorage.removeItem("token")
    }
    render() {
        return (
            <div>
                <Card className="text-center">
                    <Card.Body>
                        <Card.Title><h1>You have been logged out!!!</h1></Card.Title>
                        <Link to="/"><Button variant="secondary">Login Again</Button></Link>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
