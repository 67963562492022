import React, { useState, useEffect } from 'react';
import '.././App.css';
// import Axios from 'axios';
import axios from 'axios';
import { Card, CardBody, Table } from 'reactstrap';
import { Button } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CustomerCosting() {
    const { customer_id } = useParams();

    const classes = useStyles();

    const [customerCosting, setcustomerCosting] = useState();
    const [search, setSearch] = useState('');

    const customerCostingData = async () => {
        try {
            await axios.get('https://api.athind.com/api/product/fetch_customer_product_costing.php').then((response) => {
                console.log(response);
                const contact_data = (response.data.records);
                const filter_contact = contact_data.filter(contactNew => {
                    return contactNew.customer_id === customer_id
                });
                setcustomerCosting(filter_contact);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customerCostingData();
    }, [])

    const deleteCusCosting = async (id) => {
        await axios.delete('https://api.athind.com/api/product/delete_customer_product_costing.php/', { data: { id: id } }).then((response) => {
            setcustomerCosting(customerCosting.filter((val) => {
                return val.id !== id;
            }));
            toast.success("Customer Costing is Deleted!");
        });
    };

    // const deleteCheck = () => {
    //     let id = [];
    //     customerCosting.forEach(d => {
    //         if (d.select) {
    //             id.push(d.id);
    //         }
    //     });
    //     axios.delete('https://api.athind.com/api/product/delete_customer_product_costing.php/', {data: {id: id}}).then((response) => {
    //         console.log(response);
    //     });
    // }


    return (
        <div>
            <div className="addnewp">Customer Costing Information</div>
            <ToastContainer />
            <Card className="cus-card">
                <CardBody>
                    <div className="row">
                    {/* <div className="col">
                        <Button class="form-control" variant="danger" onClick={() => { deleteCheck() }}>Delete</Button>
                    </div> */}
                    <div className="search-div col">
                    <input type="text" class="form-control srch" placeholder="Search by date..." onChange={e => { setSearch(e.target.value) }} />
                    </div>
                    </div>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                {/* <th>action</th> */}
                                <th>ID</th>
                                <th>Product</th>
                                <th>GSM</th>
                                <th>Width</th>
                                <th>Length</th>
                                <th>Bag Color</th>
                                <th>Pattern</th>
                                <th>Gusset</th>
                                <th>Price</th>
                                <th>Wastage</th>
                                <th>Wastage Recovery</th>
                                <th>CMT</th>
                                <th>Printing</th>
                                <th>Packing</th>
                                <th>Over Heads</th>
                                <th>Profit</th>
                                <th>Sale Tax</th>
                                <th>Income Tax</th>
                                <th>Commission</th>
                                {/* <th>Bags</th>
                                <th>Price (Pcs)</th>
                                <th>Price (KG)</th> */}
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {} */}
                            {customerCosting ? customerCosting.filter((val) => {
                                if (search === "") {
                                    return val;
                                } else if (val.date.toLowerCase().includes(search.toLowerCase())) {
                                    return val;
                                }
                            }).map((val) => {
                                return <tr key={val.id}>
                                    {/* <td>
                                        <input
                                            type="checkbox" name="check[]"
                                            checked={val.select}
                                            onChange={e => {
                                                let value = e.target.checked;
                                                setcustomerCosting(
                                                    customerCosting.map(d => {
                                                        if (d.id === val.id) {
                                                            d.select = value;
                                                        }
                                                        return d;
                                                    })
                                                );
                                            }}
                                        />
                                    </td> */}
                                    <td>{val.id}</td>
                                    <td>{val.pro_title}</td>
                                    <td>{val.gsm}</td>
                                    <td>{val.width}</td>
                                    <td>{val.length}</td>
                                    <td>{val.bag_color}</td>
                                    <td>{val.patteren}</td>
                                    <td>{val.gusset}</td>
                                    <td>{val.price}</td>
                                    <td>{val.wastage}</td>
                                    <td>{val.wastagerecovery}</td>
                                    <td>{val.cmt}</td>
                                    <td>{val.printing}</td>
                                    <td>{val.packing}</td>
                                    <td>{val.overheads}</td>
                                    <td>{val.profit}</td>
                                    <td>{val.saletax}</td>
                                    <td>{val.incometax}</td>
                                    <td>{val.commission}</td>
                                    {/* <td>{val.quantity_bags}</td>
                                    <td>{val.price_per_pcs}</td>
                                    <td>{val.price_per_kg}</td> */}
                                    <td>{val.date}</td>
                                    <td className="cust-bttn">
                                        {/* <Link className="btn btn-primary eddl" to={`/pages/customer-profile/EditContact/${val.contact_id}`}>Edit</Link> */}
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteCusCosting(val.id); }}><DeleteForeverOutlinedIcon/></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default CustomerCosting
