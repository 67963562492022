import DashboardLayout from '../layouts/DashboardLayout'
import {connect}  from 'react-redux'
import {addToCart} from '../Services/Actions/Action'
import Costing from '../views/pages/costing/WcutCosting'


const mapStateToProps=state=>({

})

const mapDispatchToProps=dispatch=>({
    addToCartHandler:data=>dispatch(addToCart(data))
})


export default connect(mapStateToProps,mapDispatchToProps)(DashboardLayout)


// export default DashboardLayout;