import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '.././App.css';
import validator from 'validator';

function EditContact() {
    const { contact_id } = useParams();
    const history = useHistory();

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [dept, setDept] = useState("");
    const [desig, setDesig] = useState("");
    const [payTerm, setPayTerm] = useState("");
    const [creditL, setCreditL] = useState("");
    const [payDays, setPayDays] = useState("");

    const fetch_contact_detail = async () => {
        await axios.get(`https://api.athind.com/api/customer/contact_data.php?contact_id=${contact_id}`).then((response) => {

            console.log(response);
            setId(response.data.contact_id)
            setName(response.data.contact_name)
            setEmail(response.data.contact_email)
            setTel(response.data.contact_phone)
            setDept(response.data.contact_dept)
            setDesig(response.data.contact_desig)
            setPayTerm(response.data.contact_pay_term)
            setCreditL(response.data.contact_credit_limit)
            setPayDays(response.data.contact_pay_days)
        });
    }

    useEffect(() => {
        fetch_contact_detail();
    }, []);

    const [emailError, setEmailError] = useState('');

    const SubmitButton = (e) => {

        if (validator.isEmail(email)) {
            setEmailError('Email is valid')
            if (name && tel) {
                return <button className="btn addpbtn btn-block" onClick={() => update_contact(id)}>Update Customer Contact</button>
            } else {
                return <button className="btn addpbtn btn-block" disabled>Update Customer Contact</button>
            }
        } else {
            setEmailError('Please, Enter valid Email!')
            return <button className="btn addpbtn btn-block" disabled>Update Customer Contact</button>
        }
    }



    const update_contact = async (id) => {
        await axios.put('https://api.athind.com/api/customer/update_contact.php', {
            contact_id: id,
            contact_name: name,
            contact_email: email,
            contact_phone: tel,
            contact_dept: dept,
            contact_desig: desig,
            contact_pay_term: payTerm,
            contact_credit_limit: creditL,
            contact_pay_days: payDays,
        }).then(() => {

            history.push("/pages/customer-profile/Customers")
            toast.success("Customer Contact is Updated!");
            // console.log('Updating')
        });
    };

    return (
        <div>
            <div className="addnewp">Edit Customer Contact Information</div>
            {/* <ToastContainer /> */}
            <div className="container cus">
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">ID:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" disabled value={id}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Name:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={name} onChange={(e) => { setName(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Email:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
                        <span style={{ fontSize: '13px', color: '#e13c3c' }}>{emailError}</span>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Phone #:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={tel} onChange={(e) => { setTel(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Department:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={dept} onChange={(e) => { setDept(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Designation:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={desig} onChange={(e) => { setDesig(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Pay.Term:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={payTerm} onChange={(e) => { setPayTerm(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Credit Limit:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={creditL} onChange={(e) => { setCreditL(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-sm-3">
                        <label className="cus-title">Pay.Days:</label>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className="form-control tfield" value={payDays} onChange={(e) => { setPayDays(e.target.value); }}></input>
                    </div>
                </div>
                <SubmitButton />
                {/* <button className="btn addpbtn btn-block" onClick={update_customer(id)}>Update Customer</button> */}
            </div>
        </div>
    )
}

export default EditContact