import React, { useState, useEffect } from 'react';
import '.././App.css';
// import Axios from 'axios';
import axios from 'axios';
import { Card, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
// import {Table, responsive} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import * as ReactBootStrap from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles'
import AddCustomer from './AddCustomer'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Customers() {
    const classes = useStyles();

    const [show, setShow] = useState(false);

    const [customerList, setCustomerList] = useState(null);

    let [update, setUpdate] = useState(false)

    const onUpdateHandler = () => {
        setUpdate(!update)
    }

    const modalVisibility = () => {
        setShow(!show)
    }

    // const getCustomers = () => {
    //     useEffect(() => {
    //     axios.get('https://api.athind.com/api/product/customer.php').then((response)=>{
    //       console.log(response);
    //       setCustomerList(response.data.records);
    //     });
    // },[]);
    //   };

    const customerdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/product/customer.php').then((response) => {
                console.log(response);
                setCustomerList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customerdata();
    }, [])

    const customersData = async () => {
        try {
            await axios.get('https://api.athind.com/api/product/customer.php').then((response) => {
                console.log(response);
                setCustomerList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customersData();
    }, [update])


    const deleteCustomer = async (customer_id) => {
        await axios.delete('https://api.athind.com/api/customer/delete.php/', { data: { customer_id: customer_id } }).then((response) => {
            setCustomerList(customerList.filter((val) => {
                return val.customer_id !== customer_id;
            }));
            toast.success("Customer is Deleted!");
        });
    };

    return (
        <div>
            {/* <div className="addnewp">Customer Information</div> */}
            <div className="container cus">
                {/* <Link className="btn btn-primary butn" to="/pages/customer-profile/AddCustomer">Add Customer</Link> */}

                <Button variant="primary" onClick={modalVisibility}>
                    Add Customer
                </Button>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddCustomer
                        updateHandler={onUpdateHandler}
                        modal_visibility={modalVisibility}
                    />
                </Modal.Body>
            </Modal>
            <div className="addnewp">Customer Information</div>
            {/* <div className="btn btn-primary" style={{ marginTop: '13px'}} onClick={getCustomers}>Load Customers</div> */}
            <ToastContainer />
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Address</th>
                                <th>Brand</th>
                                <th>Website</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {} */}
                            {customerList ? customerList.map((val) => {
                                return <tr key={val.customer_id}>
                                    <td>{val.customer_id}</td>
                                    <td>
                                        <Link className="" to={`/pages/customer-profile/CustomerProfile/${val.customer_id}`}>{val.customer_name}</Link>
                                    </td>
                                    <td>{val.customer_email}</td>
                                    <td>{val.customer_phone}</td>
                                    <td>{val.customer_address}</td>
                                    <td>{val.customer_brand}</td>
                                    <td>{val.customer_website}</td>
                                    <td className="cust-bttn">
                                        <Link className="btn btn-primary eddl" to={`/pages/customer-profile/EditCustomer/${val.customer_id}`}><EditOutlinedIcon /></Link>
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteCustomer(val.customer_id); }}><DeleteForeverOutlinedIcon /></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default Customers
