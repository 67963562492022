import React, { useState, useEffect } from 'react';
import '.././App.css';
import axios from 'axios';
import { Card, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Button } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function CustomerInvoice() {
    const { customer_id } = useParams();
    const classes = useStyles();
    const [customerInvoice, setCustomerInvoice] = useState(null);

    const [show, setShow] = useState(false);

    const customerinvoices = async () => {
        try {
            await axios.get('https://api.athind.com/api/customer_invoice/customer_invoice_fetch.php').then((response) => {
                console.log(response);
                const contact_data = (response.data.records);
                const filter_contact = contact_data.filter(contactNew => {
                    return contactNew.customer_id === customer_id
                });
                setCustomerInvoice(filter_contact);
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        customerinvoices();
    }, [])

    const date = new Date().toISOString().split("T")[0];

    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [invno, setinvNo] = useState();
    const [invdate, setinvDate] = useState();
    const [kgprice, setkgPrice] = useState();
    const [advance, setAdvance] = useState();
    const [discount, setDiscount] = useState();
    const [kgno, setNKg] = useState();
    const [tprice, settPrice] = useState();
    const [remPrice, setremPrice] = useState();


    const editInvoice = (customer_invoice_id) => {
        const getingArray = customerInvoice.filter(inv => {
            return inv.customer_invoice_id === customer_invoice_id
        });
        setCustomerId(getingArray[0].customer_invoice_id)
        setCustomerName(getingArray[0].customer_name)
        setinvNo(getingArray[0].invoice_no)
        setinvDate(getingArray[0].invoice_date)
        setkgPrice(getingArray[0].price_per_kg)
        setAdvance(getingArray[0].advance)
        setDiscount(getingArray[0].discount)
        setNKg(getingArray[0].no_of_kg)
        settPrice(getingArray[0].total_price)
        setremPrice(getingArray[0].remaining_price)
        setShow(!show)
    };

    const Tprice = (kgprice * kgno);
    const totalPrice = ((kgprice * kgno) - advance);
    const disPrice = (discount / 100) * totalPrice;
    const t_p = totalPrice - disPrice;

    const update_invoice = async (customerId) => {
        await axios.put('https://api.athind.com/api/customer_invoice/update_customer_invoice.php', {
            customer_invoice_id: customerId,
            invoice_date: date,
            price_per_kg: kgprice,
            advance: advance,
            discount: discount,
            no_of_kg: kgno,
            total_price: Tprice,
            remaining_price: t_p,
        }).then(() => {
            toast.success("Invoice is updated!");
        });
    };

    const deleteInvoice = async (customer_invoice_id) => {
        await axios.delete('https://api.athind.com/api/customer_invoice/delete.php/', { data: { customer_invoice_id: customer_invoice_id } }).then((response) => {
            setCustomerInvoice(customerInvoice.filter((val) => {
                return val.customer_invoice_id !== customer_invoice_id;
            }));
            toast.success("Customer invoice is Deleted!");
        });
    };


    return (
        <div>
            <div className="addnewp">Customer Invoice Information</div>
            {/* <div className="btn btn-primary" style={{ marginTop: '13px'}} onClick={getCustomers}>Load Customers</div> */}
            <ToastContainer />
            <Card className="cus-card">
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Customer Name</th>
                                <th>Product Name</th>
                                <th>Invoice No.</th>
                                <th>Invoice Date</th>
                                <th>KG Price</th>
                                <th>Advance</th>
                                <th>Discount</th>
                                <th>No. of KG</th>
                                <th>Total Price</th>
                                <th>Payable</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {} */}
                            {customerInvoice ? customerInvoice.map((val) => {
                                return <tr key={val.customer_invoice_id}>
                                    <td>{val.customer_invoice_id}</td>
                                    <td onClick={() => { editInvoice(val.customer_invoice_id); }}>{val.customer_name}</td>
                                    <td>{val.pro_title}</td>
                                    <td>{val.invoice_no}</td>
                                    <td>{val.invoice_date}</td>
                                    <td>{val.price_per_kg}</td>
                                    <td>{val.advance}</td>
                                    <td>{val.discount}</td>
                                    <td>{val.no_of_kg}</td>
                                    <td>{val.total_price}</td>
                                    <td>{val.remaining_price}</td>
                                    <td className="cust-bttn">
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteInvoice(val.customer_invoice_id); }}><DeleteForeverOutlinedIcon/></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-40w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="capture">
                <div className="m-b">
                  <div className="pull-left">
                    {/* <img width="50" alt="" className="invoice-logo" src={AppleLogo} /> */}
                    <address className="m-t-10">
                      <div className="bold text-black">ATH</div>
                      <br />
                  (123) 411-4321.
                  <br />
                    </address>
                  </div>
                  <div className="pull-right sm-m-t-20">
                    <h2 className="font-montserrat all-caps hint-text">invoice</h2>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="p-a">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="small no-margin bold all-caps text-black">Invoice to</h3>
                      <h5>{customerName}</h5>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <div>
                        <div className="pull-left bold all-caps">Invoice No :</div>
                        <div className="pull-right">{invno}</div>
                        <div className="clearfix" />
                      </div>
                      <div>
                        <div className="pull-left font-montserrat bold all-caps">Invoice date :</div>
                        <div className="pull-right">{date}</div>
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-a b-t b-b m-b-lg">
                  <div className="row">
                  {/* <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">KG Price</h5>
                      <input type="number" className="form-control tfield" value={kgprice}/>
                    </div> */}
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">Advance</h5>
                      <input type="number" className="form-control tfield" value={advance} onChange={e => setAdvance(e.target.value)} />
                    </div>
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">Discount (%)</h5>
                      <input type="number" className="form-control tfield" value={discount} onChange={e => setDiscount(e.target.value)} />
                    </div>
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">No. of KG</h5>
                      <input type="number" className="form-control tfield" value={kgno} onChange={e => setNKg(e.target.value)} />
                    </div>
                    <div className="col-sm-3 text-right">
                      <h5 className="all-caps small no-margin hint-text bold">Total</h5>
                      <h3 className="m-a-none">{Tprice}</h3>
                    </div>
                    <div className="col-sm-3 text-right">
                      <h5 className="all-caps small no-margin hint-text bold">Payable</h5>
                      <h3 className="m-a-none">{t_p}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Button block onClick={()=> update_invoice(customerId)}>Update Invoice</Button>
            </Modal.Body>
          </Modal>
        </div>
    )
}

export default CustomerInvoice
