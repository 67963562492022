import React, { useState } from 'react';
import '.././App.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const AddCustomer = ({ updateHandler, modal_visibility }) => {

    // const history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [address, setAddress] = useState("");
    const [brand, setBrand] = useState("");
    const [website, setWebsite] = useState("");

    const [emailError, setEmailError] = useState('');

    const addcustomer = () => {
        axios.post('https://api.athind.com/api/customer/create.php', {
            customer_name: name,
            customer_email: email,
            customer_phone: tel,
            customer_address: address,
            customer_brand: brand,
            customer_website: website
        }).then(() => {
            updateHandler()
            modal_visibility()
            toast.success("Customer is Added!");
            // updateHandler()
            // history.push("/CustomerProfile")
            // window.location.reload(false);
        });
    };

    const SubmitButton = () => {
        // var email = e.target.value 

        if (validator.isEmail(email)) {
            setEmailError('Email is valid')
            if (name) {
                return <button className="btn addpbtn btn-block" onClick={addcustomer}>Add New Customer</button>
            } else {
                return <button className="btn addpbtn btn-block" disabled>Add New Customer</button>
            }
        } else {
            setEmailError('Please, Enter valid Email!')
            return <button className="btn addpbtn btn-block" disabled>Add New Customer</button>
        }
    }



    return (
        <div>
            <div className="addnewp">Add Customer</div>
            {/* <ToastContainer /> */}
            <div className="container cus">
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Name:</label>
                        <input type="text" className="form-control tfield" value={name} onChange={(e) => { setName(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Email:</label>
                        <input type="email" className="form-control tfield" value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
                        {email && <span style={{ fontSize: '13px', color: '#e13c3c' }}>{emailError}</span>}
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Phone #:</label>
                        <input type="number" className="form-control tfield" value={tel} onChange={(e) => { setTel(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Address:</label>
                        <input type="text" className="form-control tfield" value={address} onChange={(e) => { setAddress(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Brand:</label>
                        <input type="text" className="form-control tfield" value={brand} onChange={(e) => { setBrand(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="">Website:</label>
                        <input type="text" className="form-control tfield" value={website} onChange={(e) => { setWebsite(e.target.value); }}></input>
                    </div>
                </div>
                <SubmitButton />
            </div>
        </div>
    )
}

export default AddCustomer
