import React, { useState } from 'react';
import '.././App.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const AddContact = ({ updateHandler, modal_visibility }) => {

    const { customer_id } = useParams();

    const [customers_id, setCustomerId] = useState(customer_id);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [dept, setDept] = useState("");
    const [desig, setDesig] = useState("");
    const [payTerm, setPayTerm] = useState("");
    const [creditL, setCreditL] = useState("");
    const [payDays, setPayDays] = useState("");

    const [emailError, setEmailError] = useState('');
    const SubmitButton = (e) => {
        // var email = e.target.value 

        if (validator.isEmail(email)) {
            setEmailError('Email is valid')
            if (name && tel) {
                return <button className="btn addpbtn btn-block" onClick={addcustomer}>Add New Customer Contact</button>
            } else {
                return <button className="btn addpbtn btn-block" disabled>Add New Customer Contact</button>
            }
        } else {
            setEmailError('Please, Enter valid Email!')
            return <button className="btn addpbtn btn-block" disabled>Add New Customer Contact</button>
        }
    }

    const addcustomer = () => {
        axios.post('https://api.athind.com/api/customer/create_contact.php', {
            customer_id: customers_id,
            contact_name: name,
            contact_email: email,
            contact_phone: tel,
            contact_dept: dept,
            contact_desig: desig,
            contact_pay_term: payTerm,
            contact_credit_limit: creditL,
            contact_pay_days: payDays
        }).then(() => {
            updateHandler()
            modal_visibility()
            // window.location.reload(false);
            toast.success("Customer Contact is Added!");
        });
    };

    return (
        <div>
            <div className="addnewp">Add Customer Contact</div>
            {/* <ToastContainer /> */}
            <div className="container cus">
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Name:</label>
                        <input type="text" className="form-control tfield" value={name} onChange={(e) => { setName(e.target.value); }}></input>
                        <input type="text" className="form-control tfield" hidden disabled value={customers_id} onChange={(e) => { setCustomerId(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Email:</label>
                        <input type="email" className="form-control tfield" value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
                        {email && <span style={{ fontSize: '13px', color: '#e13c3c' }}>{emailError}</span>}
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Phone #:</label>
                        <input type="tel" className="form-control tfield" value={tel} onChange={(e) => { setTel(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Department:</label>
                        <input type="text" className="form-control tfield" value={dept} onChange={(e) => { setDept(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Designation:</label>
                        <input type="text" className="form-control tfield" value={desig} onChange={(e) => { setDesig(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Pay.Term:</label>
                        <input type="text" className="form-control tfield" value={payTerm} onChange={(e) => { setPayTerm(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Credit Limit:</label>
                        <input type="text" className="form-control tfield" value={creditL} onChange={(e) => { setCreditL(e.target.value); }}></input>
                    </div>
                </div>
                <div className="row fie-pad">
                    <div className="col-lg-12">
                        <label className="cus-title-modal">Pay.Days:</label>
                        <input type="text" className="form-control tfield" value={payDays} onChange={(e) => { setPayDays(e.target.value); }}></input>
                    </div>
                </div>

                <SubmitButton />
            </div>
        </div>
    )
}

export default AddContact
