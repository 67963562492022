import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '.././App.css';
import validator from 'validator';

function EditCustomer() {
    // console.log("Mounted")
    const history = useHistory();
    const { customer_id } = useParams();

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [address, setAddress] = useState("");
    const [brand, setBrand] = useState("");
    const [website, setWebsite] = useState("");

    const fetch_customer_detail = async () => {
        await axios.get(`https://api.athind.com/api/customer/customer_data.php?customer_id=${customer_id}`).then((response) => {

            console.log(response);
            setId(response.data.customer_id)
            setName(response.data.customer_name)
            setEmail(response.data.customer_email)
            setTel(response.data.customer_phone)
            setAddress(response.data.customer_address)
            setBrand(response.data.customer_brand)
            setWebsite(response.data.customer_website)
            //   setProductList(response.data);
        });
    }

    useEffect(() => {
        fetch_customer_detail();
    }, []);

    const [emailError, setEmailError] = useState('');

        const SubmitButton = (e) => {

        if (validator.isEmail(email)) {
          setEmailError('Email is valid')
          if (name && tel) {
            return <button className="btn addpbtn btn-block" onClick={()=>update_customer(id)}>Update Customer</button>
          } else {
            return <button className="btn addpbtn btn-block" disabled>Update Customer</button>
          }
        } else {
          setEmailError('Please, Enter valid Email!')
          return <button className="btn addpbtn btn-block" disabled>Update Customer</button>
        }
      }



    const update_customer = async (id) => {
        await axios.put('https://api.athind.com/api/customer/update_customer.php',{
            customer_id:id,
            customer_name: name,
            customer_email: email,
            customer_phone: tel,
            customer_address: address,
            customer_brand: brand,
            customer_website: website
        }).then( ()=>{
           
            history.push("/pages/customer-profile/Customers")
            toast.success("Customer is Updated!");
            // console.log('Updating')
        });
    };

    return (
        <div>
            <div className="addnewp">Edit Customer Information</div>
                    {/* <ToastContainer /> */}
                    <div className="container cus">
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">ID:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" disabled value={id}></input>
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Name:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={name} onChange={(e) => { setName(e.target.value); }}></input>
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Email:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={email} onChange={(e) => { setEmail(e.target.value); }}></input>
                                <span style={{ fontSize: '13px', color: '#e13c3c'}}>{emailError}</span> 
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Phone #:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={tel} onChange={(e) => { setTel(e.target.value); }}></input>
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Address:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={address} onChange={(e) => { setAddress(e.target.value); }}></input>
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Brand:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={brand} onChange={(e) => { setBrand(e.target.value); }}></input>
                            </div>
                        </div>
                        <div className="row fie-pad">
                            <div className="col-sm-2">
                                <label className="cus-title">Website:</label>
                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control tfield" value={website} onChange={(e) => { setWebsite(e.target.value); }}></input>
                            </div>
                        </div>
                        <SubmitButton />
                        {/* <button className="btn addpbtn btn-block" onClick={()=>update_customer(id)}>Update Customer</button> */}
                    </div>
        </div>
    )
}

export default EditCustomer
