import React, { useState, useEffect, Component } from 'react';
import '.././App.css';
import Axios from 'axios';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Accordion, Button } from 'react-bootstrap';
// import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
// import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MenuItem, Select, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal'
import AppleLogo from '../../../assets/images/apple.png';
import MSLogo from '../../../assets/images/microsoft.png';
import { times } from 'lodash';
var invNum = require('invoice-number')

const doc = new jsPDF()

const Costing = (props) => {

  console.warn("w-props",props);

  const dte = new Date()
  const inv = 'inv-' + dte.getFullYear() + '' + (dte.getMonth() + 1) + '' + dte.getDate() + '' + dte.getHours() + '' + dte.getMinutes() + '' + dte.getSeconds();

  /* start collapse */
  // const [open,setOpen]=useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [cusInfo, setCusInfo] = useState(false);
  const [finshSize, setFinshSize] = useState(false);
  /* end collapse */

  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [brand, setBrand] = useState();

  const [advance, setAdvance] = useState(0);
  const [nKg, setNKg] = useState(1);
  const [discount, setDiscount] = useState(0);

  const [width, setWidth] = useState();
  const [lenght, setLenght] = useState();
  const [gusset, setGusset] = useState();
  const [bagColor, setBagColor] = useState("");
  const [patteren, setPatteren] = useState("");
  const [gsm, setGsm] = useState();
  const [wastage, setWastage] = useState();
  const [fabricp, setFabricp] = useState();
  const [cmt, setCmt] = useState();
  const [packing, setPacking] = useState();
  const [printColr, setPrintColr] = useState('');
  const [printing, setPrinting] = useState();
  const [wastagerec, setWastagerec] = useState();
  const [overheads, setOverheads] = useState();
  const [profit, setProfit] = useState();
  const [saletax, setSaletax] = useState();
  const [incometax, setIncometax] = useState();
  const [commission, setCommission] = useState();

  let [accessories, setAccessories] = useState(false)
  const [count, setCount] = useState()
  const [inputField, setInputField] = useState([
    { accessName: '', accessory: '0' },
  ]);

  // console.log(inputField);

  useEffect(() => {
    if (inputField[0].accessory) {
      let cont = 0
      // console.log('Araa',inputField.length)
      for (let i = 0; i < inputField.length; i++) {
        cont = cont + Number(inputField[i].accessory)
        setCount(cont)
      }
      // count = count+1
    }
  }, [accessories])

  // console.log('count====', count)

  const handleChangeAcc = (i, e) => {
    let values = [...inputField];
    values[i][e.target.name] = e.target.value;
    setInputField(values);
    setAccessories(!accessories)
    // console.log(e.target.value)

  }

  const handleAddFields = () => {
    setInputField([...inputField, { accessName: '', accessory: '0' }])
    // console.log('runing')
  }

  const handleRemoveFields = (i) => {
    const values = [...inputField];
    values.splice(i, 1);
    setInputField(values);
    // console.log('bak')
  }

  const date = new Date().toISOString().split("T")[0];
  // console.log(date);

  const fabricreq = ((Number(width) + Number(gusset)) * 2) + 1.5;

  const fabricconsump = ((fabricreq * lenght * gsm) / 1550 / 1000);
  const round_fc = Math.round(fabricconsump * 10000) / 10000;

  const wast_per = wastage / 100;
  const wast_mul = round_fc * wast_per;
  const wast_min = round_fc - wast_mul;
  const wast_div = 1 / wast_min;
  const round_qbag_kg = Math.round(wast_div * 1) / 1;

  const bg_net_wg = (round_fc - (round_fc * wast_per));

  const fabric_cost = bg_net_wg * fabricp;
  const round_fcost = Math.round(fabric_cost * 100) / 100;

  const fabric_ckg = round_fcost / bg_net_wg * 1;
  const round_fabric_ckg = Math.round(fabric_ckg * 1) / 1;

  const wastagef = (round_fcost * wastage) / 100;
  const round_wf = Math.round(wastagef * 100) / 100;

  const wastage_kg = (round_wf / bg_net_wg * 1);
  const round_wkg = Math.round(wastage_kg * 1) / 1;

  const cmt_f = cmt * bg_net_wg;
  const round_cmt_f = Math.round(cmt_f * 100) / 100;

  const cmt_kg = round_cmt_f / bg_net_wg * 1;
  const round_cmt_kg = Math.round(cmt_kg * 1) / 1;

  const packing_f = packing * bg_net_wg;
  const round_pf = Math.round(packing_f * 100) / 100;

  const packing_kg = round_pf / bg_net_wg * 1;
  const round_packing_kg = Math.round(packing_kg * 1) / 1;

  const printing_p = printing * bg_net_wg;
  const round_printing_p = Math.round(printing_p * 100) / 100;

  const printing_kg = round_printing_p / bg_net_wg * 1;
  const round_printing_kg = Math.round(printing_kg * 1) / 1;

  const wastage_reckg = round_wkg * (-wastagerec / fabricp);
  const round_wrkg = Math.round(wastage_reckg * 1) / 1;

  const wastage_rp = round_wrkg * bg_net_wg;
  const round_wastage_rp = Math.round(wastage_rp * 100) / 100;

  const overheads_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf) * overheads / 100;
  const round_overheads_p = Math.round(overheads_p * 100) / 100;

  const overheads_kg = round_overheads_p / bg_net_wg * 1;
  const round_overheads_kg = Math.round(overheads_kg * 1) / 1;

  const profit_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p) * profit / 100;
  const round_profit_p = Math.round(profit_p * 100) / 100;

  const profit_kg = round_profit_p / bg_net_wg * 1;
  const round_profit_kg = Math.round(profit_kg * 1) / 1;

  const saletax_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p) * saletax / 100;
  const round_saletax_p = Math.round(saletax_p * 100) / 100;

  const saletax_kg = round_saletax_p / bg_net_wg * 1;
  const round_saletax_kg = Math.round(saletax_kg * 1) / 1;

  const incometax_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p) * incometax / 100;
  const round_incometax_p = Math.round(incometax_p * 100) / 100;

  const incometax_kg = round_incometax_p / bg_net_wg * 1;
  const round_incometax_kg = Math.round(incometax_kg * 1) / 1;

  const sales_tax = round_saletax_kg + round_incometax_kg;

  const commission_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p + round_incometax_p) * commission / 100;
  const round_commission_p = Math.round(commission_p * 100) / 100;

  const commission_kg = round_commission_p / bg_net_wg * 1;
  const round_commission_kg = Math.round(commission_kg * 1) / 1;

  const access = count * bg_net_wg;
  const round_access = Math.round(access * 100) / 100;

  const access_kg = round_access / bg_net_wg * 1;
  const round_access_kg = Math.round(access_kg * 1) / 1;

  const selling_kg = (round_fabric_ckg + round_wkg + round_cmt_kg + round_packing_kg + round_printing_kg + round_wrkg + round_overheads_kg + round_profit_kg + round_saletax_kg + round_incometax_kg + round_commission_kg + round_access_kg);

  const selling_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p + round_incometax_p + round_commission_p + round_access);
  const round_selling_p = Math.round(selling_p * 100) / 100;

  const selling_exclud_kg = (round_fabric_ckg + round_wkg + round_cmt_kg + round_packing_kg + round_printing_kg + round_wrkg + round_overheads_kg + round_profit_kg + round_commission_kg + round_access_kg);

  const selling_p_exclud = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_commission_p + round_access);
  const round_selling_p_exclud = Math.round(selling_p_exclud * 100) / 100;

  const Tprice = (selling_kg * nKg);
  const totalPrice = ((selling_kg * nKg) - advance);
  const disPrice = (discount / 100) * totalPrice;
  const rem_price = totalPrice - disPrice;



  // const selling_p = ((1000/round_qbag_kg)*selling_kg)/1000;
  // const round_selling_p = Math.round(selling_p * 100) / 100;

  // const selling_p_exclud = ((1000/round_qbag_kg)*selling_exclud_kg)/1000;
  // const round_selling_p_exclud = Math.round(selling_p_exclud * 100) / 100;

  const handleFocus = (event) => event.target.select();


  const [wcut, setWcutList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  // const [wcutData, setWcutData] = useState([]);

  // const getProducts = () => {
  useEffect(() => {
    Axios.get('https://api.athind.com/api/product/wcut_list.php').then((response) => {
      console.log(response);
      setWcutList(response.data.records);
    });
  }, []);
  // };

  const [proTitle, setProTitle] = useState();

  const getdata = (id) => {
    const getingArray = wcut.filter(wcutNew => {
      return wcutNew.id === id
    })
    // console.log(getingArray)
    setProTitle(getingArray[0].pro_title)
    setWidth(getingArray[0].width)
    setLenght(getingArray[0].lenght)
    setGusset(getingArray[0].gusset)
    setBagColor(getingArray[0].bag_color)
    setPatteren(getingArray[0].patteren)
    setGsm(getingArray[0].gsm)
    setFabricp(getingArray[0].price)
    setWastage(getingArray[0].wastage)
    setWastagerec(getingArray[0].wastagerecovery)
    setCmt(getingArray[0].cmt)
    setPrintColr(getingArray[0].printing_color)
    setPrinting(getingArray[0].printing)
    setPacking(getingArray[0].packing)
    setOverheads(getingArray[0].overheads)
    setProfit(getingArray[0].profit)
    setSaletax(getingArray[0].saletax)
    setIncometax(getingArray[0].incometax)
    setCommission(getingArray[0].commission)
  };

  const customerdata = async () => {
    try {
      await Axios.get('https://api.athind.com/api/product/customer.php').then((response) => {
        console.log(response);
        setCustomerList(response.data.records);
      });
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    customerdata();
  }, [])



  const getCustomerData = (customer_id) => {
    const getingArray = customerList.filter(wcutNew => {
      return wcutNew.customer_id === customer_id
    })
    // console.log(getingArray)
    setCustomerId(getingArray[0].customer_id)
    setCustomerName(getingArray[0].customer_name)
    setEmail(getingArray[0].customer_email)
    setPhone(getingArray[0].customer_phone)
    setAddress(getingArray[0].customer_address)
    setBrand(getingArray[0].customer_brand)
  };

  const [value, setValue] = useState('');
  const [opens, setOpens] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleOpen = () => {
    setOpens(true);
  };

  const [customerValue, setCustomerValue] = useState('');
  const [openc, setOpenc] = useState(false);

  const handleChangeCustomer = (e) => {
    setCustomerValue(e.target.value);
  };

  const handleCloseCustomer = () => {
    setOpenc(false);
  };

  const handleOpenCustomer = () => {
    setOpenc(true);
  };

  const [costForm, setCostForm] = useState();

  const handleCostForm = (e) => {
    setCostForm(e.target.value);
  };
  // console.log(costForm);

  // function SubmitPdfButton() {
  //   if (customerName && email && phone && address && proTitle && gsm && bagColor && width && lenght && gusset
  //     && round_qbag_kg && round_selling_p_exclud && selling_exclud_kg) {
  //     Axios.post('https://api.athind.com/mailer/mailer/mail.php', {
  //       customer_name: customerName,
  //       customer_email: email,
  //       customer_phone: phone,
  //       customer_address: address,
  //       product_name: proTitle,
  //       gsm: gsm,
  //       bag_color: bagColor,
  //       width: width,
  //       length: lenght,
  //       gusset: gusset,
  //       quantity_bags: round_qbag_kg,
  //       price_per_pcs: round_selling_p_exclud,
  //       price_per_kg: selling_exclud_kg
  //     }).then(() => {
  //       toast.success("PDF is Generated!");
  //     });
  //   } else {
  //     toast.error("Please fill all the fields!");
  //   }
  // }



  function SubmitPdfButton() {
    // doc.text("sannan somi", 10, 10);
    if (proTitle) {
      doc.autoTable({
        styles: { fontSize: [18] },
        head: [['Attributes', 'Values']],
        body: [['GSM', gsm], ['Width', width], ['Length', lenght], ['Gusset', gusset], ['Bag Color', bagColor], ['Pattern', patteren], ['No. of Bags', round_qbag_kg], ['Price per piece', round_selling_p], ['Price per KG', selling_kg]],
      })
      doc.save('table.pdf')
    } else {
      toast.error("Please select product!");
    }
    // if (proTitle) {
    //   Axios.post('https://api.athind.com/tcpdf/htmltopdf.php', {
    //     gsm: gsm,
    //     gusset: gusset,
    //     width: width,
    //     length: lenght,
    //     bag_color: bagColor,
    //     patteren: patteren,
    //     quantity_bags: round_qbag_kg,
    //     price_per_pcs: round_selling_p_exclud,
    //     price_per_kg: selling_exclud_kg,
    //     date: date
    //   }).then(() => {
    //     toast.success("File Saved!");
    //   });
    // } else {
    //   toast.error("Please select product!");
    // }
  }

  function SubmitInvoice() {
    if (proTitle && customerId && email && phone && address && gsm && bagColor && width && lenght && gusset
      && round_qbag_kg && round_selling_p_exclud && selling_exclud_kg) {
      Axios.post('https://api.athind.com/mailer/mailer/mail.php', {
        pro_title: proTitle,
        customer_id: customerId,
        customer_name: customerName,
        customer_email: email,
        customer_phone: phone,
        customer_address: address,
        gsm: gsm,
        price: fabricp,
        bag_color: bagColor,
        patteren: patteren,
        width: width,
        length: lenght,
        gusset: gusset,
        wastage: wastage,
        wastagerecovery: wastagerec,
        cmt: cmt,
        printing: printing,
        packing: packing,
        overheads: overheads,
        profit: profit,
        saletax: saletax,
        incometax: incometax,
        commission: commission,
        quantity_bags: round_qbag_kg,
        price_per_pcs: round_selling_p_exclud,
        price_per_kg: selling_exclud_kg,
        date: date
      }).then(() => {
        toast.success("Customer costing is save and send!");
      });
    } else {
      toast.error("Please select product & customer!");
    }

  }

  // const generatePDF = () => {
  //   Axios.post('https://api.athind.com/mailer/mailer/mail.php', {
  //     customer_name: customerName,
  //     customer_email: email,
  //     customer_phone: phone,
  //     customer_address: address,
  //     product_name: proTitle,
  //     gsm: gsm,
  //     bag_color: bagColor,
  //     width: width,
  //     length: lenght,
  //     gusset: gusset,
  //     quantity_bags: round_qbag_kg,
  //     price_per_pcs: round_selling_p_exclud,
  //     price_per_kg: selling_exclud_kg
  //   }).then(() => {
  //     toast.success("PDF is Generated!");
  //   });
  // }

  const [show, setShow] = useState(false);
  const openModal = () => {
    if (proTitle && customerName) {
      setShow(!show)
    } else {
      toast.error("Please select product & customer!");
    }
  }

  const generatePDF = () => {
    if (customerName && proTitle) {
      Axios.post('https://api.athind.com/api/customer_invoice/invoice_generate.php', {
        customer_id: customerId,
        customer_name: customerName,
        pro_title: proTitle,
        invoice_no: inv,
        invoice_date: date,
        price_per_kg: selling_kg,
        advance: advance,
        discount: discount,
        no_of_kg: nKg,
        total_price: Tprice,
        remaining_price: rem_price
      }).then(() => {
        html2canvas(document.querySelector("#capture")).then(canvas => {
          // document.body.appendChild(canvas);  // if you want see your screenshot in body.
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 15, 15, 180, 130); // 180x150 mm @ (15,15)mm
          pdf.save("Invoice.pdf");
        });
      });
    } else {
      toast.error("Something went wrong!");
    }
  }

  const addToCart = (customerId) =>{
    // localStorage.setItem('width', width);
    alert(customerId)
  }

  return (
    <div className="App">
      <div className="addnewp">Costing for W-Cut</div>
      {/* start Costing */}
      <div className="particulars">
        <ToastContainer />

        {/* {wcutData.map((val, key) => { */}
        {/* return<div> */}
        {/* <h1>{val.id}</h1> */}

        <div className="row internal-data">
          <div className="col-lg-8 col-sm-12 fixed">
            <div className="row">
              <div className="col">
                <div className="cost-select-heading">Select Product</div>
                <Select id="demo-controlled-open-select" open={opens} onClose={handleClose}
                  onOpen={handleOpen} value={value} onChange={handleChange}>
                  {/* <MenuItem value="somi">
            <em>None</em>
          </MenuItem> */}
                  {wcut.map((val, key) => {
                    return <MenuItem key={val.id} value={val.id} onClick={() => { getdata(val.id); }}>{val.pro_title}</MenuItem>
                  })}
                </Select>
              </div>

              <div className="col">
                <div className="cost-select-heading">Select Customer</div>
                <Select id="demo-controlled-open-select" open={openc} onClose={handleCloseCustomer}
                  onOpen={handleOpenCustomer} value={customerValue} onChange={handleChangeCustomer}>
                  {/* <MenuItem value="somi">
            <em>None</em>
          </MenuItem> */}
                  {customerList.map((val, key) => {
                    return <MenuItem key={val.customer_id} value={val.customer_id} onClick={() => { getCustomerData(val.customer_id); }}>{val.customer_name}</MenuItem>
                  })}
                </Select>
              </div>

              <div className="col">
                <div className="cost-select-heading">Select Pcs/Kg</div>
                <Select id="demo-controlled-open-select" value={costForm} onChange={handleCostForm}>
                  <MenuItem value="pcs">
                    Pcs
                  </MenuItem>
                  <MenuItem value="kg">
                    Kg
                  </MenuItem>
                  <MenuItem value="both">
                    Pcs and Kg
                  </MenuItem>
                </Select>
              </div>

              <div className="col"><Button className="cost-select-heading" onClick={SubmitPdfButton}>Download PDF</Button></div>
              {/* <div className="col"><SubmitPdfButton /></div> */}
            </div>

            <div className="basic-row">
              <Accordion defaultActiveKey="1">
                <Accordion.Toggle as={Button} onClick={() => setCusInfo(!cusInfo)} className="btn ebasicbtn" variant="link" eventKey="1">
                  Customer Info {cusInfo ? <ExpandMoreOutlinedIcon className="basicbtn" /> : <ExpandLessOutlinedIcon className="basicbtn" />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="partitle">Customer Email</div>
                        <input type="text" className="form-control tfield" disabled value={email} />
                      </div>
                      <div className="col-sm-3">
                        <div className="partitle">Customer Brand</div>
                        <input type="text" className="form-control tfield" disabled value={brand} />
                      </div>
                      <div className="col-sm-3">
                        <div className="partitle">Customer Phone</div>
                        <input type="number" className="form-control tfield" disabled value={phone} />
                      </div>
                      <div className="col-sm-3">
                        <div className="partitle">Customer Address</div>
                        <input type="text" className="form-control tfield" disabled value={address} />
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>

            {/* start finished size */}
            <div>
              <Accordion defaultActiveKey="1">
                <Accordion.Toggle as={Button} onClick={() => setFinshSize(!finshSize)} className="btn ebasicbtn" variant="link" eventKey="1">
                  Finished Size {finshSize ? <ExpandMoreOutlinedIcon className="basicbtn" /> : <ExpandLessOutlinedIcon className="basicbtn" />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">gsm</div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={gsm} onChange={e => setGsm(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Width <span className="psubtitle">(Inches)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={width} onChange={e => setWidth(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Length <span className="psubtitle">(Inches)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={lenght} onChange={e => setLenght(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Side Gusset <span className="psubtitle">(Inches)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={gusset} onChange={e => setGusset(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Fabric Price <span className="psubtitle">(Per KG)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={fabricp} onChange={e => setFabricp(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Wastage <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={wastage} onChange={e => setWastage(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Wastage Rec. <span className="psubtitle">(Per KG)</span></div>
                            <input type="number" className="form-control tfield" min="0" onFocus={handleFocus} value={wastagerec} onChange={e => setWastagerec(+e.target.value.replace(/[-]/, ""))} />
                          </div>
                          <div className="col">
                            <div className="partitle">Bag Color</div>
                            <input type="text" className="form-control tfield" onFocus={handleFocus} value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Pattern</div>
                            <input type="text" className="form-control tfield" onFocus={handleFocus} value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                          </div>
                          <div className="col">
                            <div className="partitle">CMT <span className="psubtitle">(Per KG)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={cmt} onChange={e => setCmt(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Printing Color</div>
                            <input type="text" className="form-control tfield" onFocus={handleFocus} value={printColr} onChange={e => setPrintColr(e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Printing <span className="psubtitle">(Per KG)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={printing} onChange={e => setPrinting(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Packing <span className="psubtitle">(Per KG)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={packing} onChange={e => setPacking(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Over Heads <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={overheads} onChange={e => setOverheads(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Profit <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={profit} onChange={e => setProfit(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Sale Tax <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={saletax} onChange={e => setSaletax(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col">
                            <div className="partitle">Income Tax <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={incometax} onChange={e => setIncometax(+e.target.value)} />
                          </div>
                          <div className="col">
                            <div className="partitle">Commission <span className="psubtitle">(%)</span></div>
                            <input type="number" className="form-control tfield" onFocus={handleFocus} value={commission} onChange={e => setCommission(+e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
            {/* end finished size */}
            <div className="basic-row">
              <Accordion defaultActiveKey="1">
                <Accordion.Toggle as={Button} onClick={() => setAddAccess(!addAccess)} className="btn ebasicbtn" variant="link" eventKey="1">
                  Add Accessories {addAccess ? <ExpandMoreOutlinedIcon className="basicbtn" /> : <ExpandLessOutlinedIcon className="basicbtn" />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div>
                    <div className="row">
                      {inputField.map((inp, i) => (
                        <div key={i} className="col-sm-4">
                          <div className="partitle">Name</div>
                          <input type="text"
                            name="accessName"
                            className="form-control tfield"
                            value={inp.accessName}
                            onChange={e => handleChangeAcc(i, e)}
                          />
                          <div className="partitle">Value</div>
                          <input type="number"
                            name="accessory"
                            className="form-control tfield"
                            value={inp.accessory}
                            onChange={e => handleChangeAcc(i, e)}
                          />
                          {inputField.length !== 1 && <IconButton onClick={() => handleRemoveFields(i)}>
                            <RemoveIcon />
                          </IconButton>}
                          {inputField.length - 1 === i && <IconButton onClick={() => handleAddFields()}>
                            <AddIcon />
                          </IconButton>}
                        </div>
                      ))}
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>


            <div className="row">
              <div className="col-sm-4">
                <div className="costing_div">Fabric Required</div>
                <input type="number" disabled className="form-control tfield" value={fabricreq} />
              </div>
              <div className="col-sm-4">
                <div className="costing_div">Fabric Consumption</div>
                <input type="number" disabled className="form-control tfield" value={round_fc} />
              </div>
              <div className="col-sm-4">
                <div className="costing_div">Quantity Bags</div>
                <input type="number" disabled className="form-control tfield" value={round_qbag_kg} />
              </div>
            </div>
          </div>


          <div className="col-lg-4 col-sm-12">
            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Selling P. <span className="psubtitle">(Exc. Tax)</span></div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p_exclud} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_exclud_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">Selling P. <span className="psubtitle">(Inc. Tax)</span></div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Fabric Cost</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_fcost} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_fabric_ckg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">CMT</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_f} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Wastage</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wf} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wkg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">Wastage Recovery</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wastage_rp} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wrkg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Packing</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_pf} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_packing_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">Printing</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Over Heads</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">Profit</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Sale Tax</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="cost_title">Income Tax</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col cost-bord-new">
                <div className="row">
                  <div className="col">
                    <div className="cost_title">Commission</div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col">
                    <div className="cost_title">Selling P. <span className="psubtitle">(Inc. Tax)</span></div>
                    <div className="row cost_out">
                      <div className="col">
                        {costForm === "pcs" || costForm === "both" ? <div>
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p} />
                        </div> : false}
                        {costForm === "kg" || costForm === "both" ? <div>
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_kg} />
                        </div> : false}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <Button block onClick={SubmitInvoice}>Save & Send Customer Costing</Button>
              <Button block onClick={openModal}>Make Invoice</Button>
              <Button block onClick={()=>addToCart(customerId)}>Add Cart</Button>
            </div>
          </div>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-40w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="capture">
                <div className="m-b">
                  <div className="pull-left">
                    {/* <img width="50" alt="" className="invoice-logo" src={AppleLogo} /> */}
                    <address className="m-t-10">
                      <div className="bold text-black">ATH Industries</div>
                      plot# B-61, phase 1B, M3 industrial city, near<br /> Sahianwala interchange Motorway, <b>Faisalabad</b>
                      <br />
                      <b>Tel:</b> (123) 411-4321.
                    </address>
                  </div>
                  <div className="pull-right sm-m-t-20">
                    <h2 className="font-montserrat all-caps hint-text">invoice</h2>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="p-a">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="no-margin bold all-caps text-black">Invoice to</h3>
                      <h5>{customerName}</h5>
                      {/* <h5>{brand}</h5> */}
                      <address>
                        {address} <br />
                        <b>Tel:</b> {phone}.
                        {/* <strong>Inceptious Technologies.</strong>
                    <br />
                    (123) 411-4321
                    <br />
                    P-15, Street # 15, near Wapda office,
                    <br />
                    Sargodha road, Faisalabad, Pakistan */}
                      </address>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <div>
                        <div className="pull-left bold all-caps">Invoice No :</div>
                        <div className="pull-right">{inv}</div>
                        <div className="clearfix" />
                      </div>
                      <div>
                        <div className="pull-left font-montserrat bold all-caps">Invoice date :</div>
                        <div className="pull-right">{date}</div>
                        <div className="clearfix" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive m-b">
                  <table className="table m-t-50">
                    <thead>
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Brand</th>
                        <th className="text-center">Bag Color</th>
                        <th className="text-center">Sales Tax</th>
                        <th className="text-center">Price Per KG</th>
                        <th className="text-center">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>
                        <p className="text-black">{proTitle}</p>
                      </td>
                      <td className="text-center">{brand}</td>
                      <td className="text-center">{bagColor}</td>
                      <td className="text-center">{sales_tax}</td>
                      <td className="text-center">{selling_kg}</td>
                      <td className="text-center">{printColr}</td>
                    </tbody>
                  </table>
                </div>
                {/* <div className="m-b">
              <img width="150" alt="" className="invoice-signature" src={MSLogo} />
              <p>Designer’s Identity</p>
            </div> */}
                <div className="p-a b-t b-b m-b-lg">
                  <div className="row">
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">Advance</h5>
                      {/* <h3 className="no-margin">20,000</h3> */}
                      <input type="number" className="form-control tfield" value={advance} onChange={e => setAdvance(e.target.value)} />
                    </div>
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">Discount (%)</h5>
                      {/* <h3 className="no-margin">5000</h3> */}
                      <input type="number" className="form-control tfield" value={discount} onChange={e => setDiscount(e.target.value)} />
                    </div>
                    <div className="col-sm-2 clearfix">
                      <h5 className="font-montserrat all-caps small no-margin hint-text bold">No. of KG</h5>
                      {/* <h3 className="no-margin">5000</h3> */}
                      <input type="number" className="form-control tfield" value={nKg} onChange={e => setNKg(e.target.value)} />
                    </div>
                    <div className="col-sm-3 text-right">
                      <h5 className="all-caps small no-margin hint-text bold">Total</h5>
                      <h3 className="m-a-none">{Tprice}</h3>
                    </div>
                    <div className="col-sm-3 text-right">
                      <h5 className="all-caps small no-margin hint-text bold">Remaining</h5>
                      <h3 className="m-a-none">{rem_price}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <Button block onClick={generatePDF}>Download Invoice</Button>
            </Modal.Body>
          </Modal>
        </div>

        {/* start finished size row */}

        {/* <div className="basic-row">
            <Accordion defaultActiveKey="1">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Finished Size <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">gsm</div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={gsm} onChange={e => setGsm(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Width <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={width} onChange={e => setWidth(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Length <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={lenght} onChange={e => setLenght(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Side Gusset <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={gusset} onChange={e => setGusset(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Fabric Price <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={fabricp} onChange={e => setFabricp(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Wastage <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={wastage} onChange={e => setWastage(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Wastage Recovery (-) <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" min="0" onFocus={handleFocus} value={wastagerec} onChange={e => setWastagerec(+e.target.value.replace(/[-]/, ""))} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">CMT <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={cmt} onChange={e => setCmt(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Printing <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={printing} onChange={e => setPrinting(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Packing <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={packing} onChange={e => setPacking(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Over Heads <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={overheads} onChange={e => setOverheads(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Profit <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={profit} onChange={e => setProfit(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Sale Tax <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={saletax} onChange={e => setSaletax(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Income Tax <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={incometax} onChange={e => setIncometax(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Commission <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={commission} onChange={e => setCommission(+e.target.value)} />
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> */}

        {/* end finished size row */}

        {/* <div className="basic-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} onClick={() => setCostOpen(!costOpen)} className="btn ebasicbtn" variant="link" eventKey="1">
                Costing
                {!costOpen && <ExpandMoreOutlinedIcon className="basicbtn" />}
                {costOpen && <ExpandLessOutlinedIcon className="basicbtn" />}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Fabric Cost</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_fcost} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_fabric_ckg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">CMT</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_f} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Wastage</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wf} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wkg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Wastage Recovery</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wastage_rp} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wrkg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Packing</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_pf} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_packing_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Printing</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Over Heads</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Profit</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Sale Tax</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Income Tax</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Commission</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Selling Price <span className="psubtitle">(Inclusive Tax)</span></div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Selling Price <span className="psubtitle">(Excluded Tax)</span></div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p_exclud} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_exclud_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> */}
        {/* </div> */}

        {/* })} */}

        {/* <button className="btn addpbtn btn-block" >add new product</button> */}

      </div>
      {/* end Costing */}
    </div>
  );
}

export default Costing;
