import React, { useState, useEffect } from 'react';
import '.././App.css';
import axios from 'axios';
import { Card, CardBody, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
// import {Table, responsive} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { Loader } from '../../../vibe/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function ShowProducts() {
    const classes = useStyles();

    const [productList, setProductList] = useState(null);

    const [search, setSearch] = useState('');
    // const [update,setUpdate] = useState(false)


    // const getProducts = () => {
    //     useEffect(() => {
    //     axios.get('https://api.athind.com/api/product/list_products.php').then((response)=>{
    //       console.log(response);
    //       setProductList(response.data.records);
    //     });
    // },[]);
    //   };

    const productdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/product/list_products.php').then((response) => {
                console.log(response);
                setProductList(response.data.records);
                // const ddd = response.data.records
                // setProductList(ddd.filter(wcutNew => {
                //     return wcutNew.pro_type === "dcut"
                //   }))
            });
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        productdata();
    }, [])

    // const productsdata = async () => {
    //         await axios.get('https://api.athind.com/api/product/list_products.php').then((response)=>{
    //                   console.log(response);
    //                   setProductList(response.data.records);
    //                 });

    // };


    // useEffect(  () => {
    //     productsdata();
    // }, [update])

    const deleteProduct = async (id) => {
        await axios.delete('https://api.athind.com/api/product/delete.php', { data: { id: id } }).then((response) => {
            // console.log(response);
            // setUpdate(!update)
            setProductList(productList.filter((val) => {
                return val.id !== id;
            }));
            toast.success("Product is Deleted!");
        });

    };


    return (
        <div>
        {/* <div className="container">
          <select className="select_f">
            <option selected value="N/A">Select Product</option>
            <option value="w-cut"> W-Cut</option>
            <option value="d-cut"> D-Cut</option>
            <option value="stitched"> Stitched</option>
          </select>
      </div> */}
            <div className="addnewp">Products Information</div>
            <ToastContainer />
            <Card className="prod-card">
                <CardBody>
                <div className="search-div">
                <input type="text" class="form-control srch" placeholder="Search by name..." onChange={e => {setSearch(e.target.value)}} />
                </div>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>GSM</th>
                                <th>Price</th>
                                <th>Color</th>
                                <th>Pattern</th>
                                <th>Printing Color</th>
                                <th>Width</th>
                                <th>Length</th>
                                <th>Gusset</th>
                                <th>Top Fold</th>
                                <th>Quantity Bags</th>
                                <th>Loop Gsm</th>
                                <th>Loop Width</th>
                                <th>Loop Length</th>
                                <th>Loop Color</th>
                                <th>Piping</th>
                                <th>Wastage</th>
                                <th>Wastage Recovery</th>
                                <th>CMT</th>
                                <th>Printing</th>
                                <th>Packing</th>
                                <th>Over Heads</th>
                                <th>Profit</th>
                                <th>Sale Tax</th>
                                <th>Income Tax</th>
                                <th>Commission</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {} */}
                            {productList ? productList.filter((val) => {
                                if (search === ""){
                                    return val;
                                } else if (val.pro_title.toLowerCase().includes(search.toLowerCase())){
                                    return val;
                                }
                            }).map((val) => {
                                return <tr key={val.id}>
                                    <td>{val.id}</td>
                                    <td className="title-hover">{val.pro_title}</td>
                                    <td>{val.gsm}</td>
                                    <td>{val.price}</td>
                                    <td>{val.bag_color}</td>
                                    <td>{val.patteren}</td>
                                    <td>{val.printing_color}</td>
                                    <td>{val.width}</td>
                                    <td>{val.lenght}</td>
                                    <td>{val.gusset}</td>
                                    <td>{val.topfold}</td>
                                    <td>{val.quantity_bags}</td>
                                    <td>{val.loop_gsm}</td>
                                    <td>{val.loop_width}</td>
                                    <td>{val.loop_length}</td>
                                    <td>{val.loop_color}</td>
                                    <td>{val.piping}</td>
                                    <td>{val.wastage}</td>
                                    <td>{val.wastagerecovery}</td>
                                    <td>{val.cmt}</td>
                                    <td>{val.printing}</td>
                                    <td>{val.packing}</td>
                                    <td>{val.overheads}</td>
                                    <td>{val.profit}</td>
                                    <td>{val.saletax}</td>
                                    <td>{val.incometax}</td>
                                    <td>{val.commission}</td>
                                    <td className="proact">
                                        <Link className="btn btn-primary eddl" to={`/pages/show-product/EditProduct/${val.id}`}><EditOutlinedIcon/></Link>
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteProduct(val.id); }}><DeleteForeverOutlinedIcon/></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                                {/* <Loader type="bars" /> */}
                                {/* <Loader type="puff" /> */}
                            </Backdrop>
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default ShowProducts