import React from 'react';
import '.././App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import CustomerContact from './CustomerContact';
import CustomerCosting from './CustomerCosting';
import CustomerInvoice from './CustomerInvoice';

export default function CustomerProfile() {

    return (
        <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Customer Contacts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Customer Costing</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Customer Invoice</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <CustomerContact />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <CustomerCosting />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <CustomerInvoice />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}
