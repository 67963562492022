import React, { useState, useEffect } from 'react';
import '.././App.css';
import axios from 'axios';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import _ from "lodash";

import { enGB } from 'date-fns/locale'
import { format } from 'date-fns'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

import { ArrowRight } from 'react-feather';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function BalanceSheet() {

    const classes = useStyles();

    const [sheet, setSheet] = useState([]);

    const customerdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/customer_invoice/customer_invoice_fetch.php').then((response) => {
                // console.log(response);
                setSheet(response.data.records);
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        customerdata();
    }, [])

    
    // const gettTotal = sheet.reduce((total, row) => total + Number(row.total_price), 0);
    // const gettTotal = _.sum(_.map(sheet, d => Number(d.total_price)));
    const gettTotal = 0;


    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const min = startDate ? format(startDate, 'yyyy-MM-dd') : 'Select Date';
    const max = endDate ? format(endDate, 'yyyy-MM-dd') : 'Select Date';
    // console.log(min);

    return (
        <div>
            {/* <p>Selected date: {startDate ? format(startDate, 'yyyy-MM-dd', { locale: enGB }) : 'none'}.</p>
        <p>Selected end date: {endDate ? format(endDate, 'yyyy-MM-dd', { locale: enGB }) : 'none'}.</p> */}
            <div className="addnewp">Balance Sheet</div>
            <ToastContainer />
            <Card>
                <div className="cost-select-heading date-range">Please Select Start & End Date</div>
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    // minimumDate={new Date()}
                    // minimumLength={1}
                    format='yyyy-MM-dd'
                    locale={enGB}
                >
                    {({ startDateInputProps, endDateInputProps, focus }) => (
                        <div className='date-range'>
                            <input
                                className={'input date-input' + (focus === START_DATE ? ' -focused' : '')}
                                {...startDateInputProps}
                                placeholder='Start date'
                            />
                            <ArrowRight className="date-arrow" />
                            <input
                                className={'input date-input' + (focus === END_DATE ? ' -focused' : '')}
                                {...endDateInputProps}
                                placeholder='End date'
                            />
                        </div>
                    )}
                </DateRangePicker>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Invoice #</th>
                                <th>Invoice Date</th>
                                <th>Price per KG</th>
                                <th>Advance</th>
                                <th>Discount</th>
                                <th>No. of KG</th>
                                <th>Total Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sheet ? sheet.filter(
                                val => (val.invoice_date) >= min && (val.invoice_date) <= max
                            ).map(val => {
                                return <tr key={val.customer_invoice_id}>
                                    <td>{val.customer_invoice_id}</td>
                                    <td>{val.customer_name}</td>
                                    <td>{val.invoice_no}</td>
                                    <td>{val.invoice_date}</td>
                                    <td>{val.price_per_kg}</td>
                                    <td>{val.advance}</td>
                                    <td>{val.discount}</td>
                                    <td>{val.no_of_kg}</td>
                                    <td className="ttt" id="id">{val.total_price}</td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            }
                            {/* {sheet ? sheet.map((val) => {
                                return <tr key={val.customer_invoice_id}>
                                    <td>{val.customer_invoice_id}</td>
                                    <td>{val.customer_name}</td>
                                    <td>{val.invoice_no}</td>
                                    <td>{val.invoice_date}</td>
                                    <td>{val.price_per_kg}</td>
                                    <td>{val.advance}</td>
                                    <td>{val.discount}</td>
                                    <td>{val.no_of_kg}</td>
                                    <td>{val.total_price}</td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            } */}
                            {/* <tr>
                                <td colSpan={6}></td>
                                <td className="table-left" colSpan={2}>Subtotal</td>
                                <td align="right">900000</td>
                            </tr>
                            <tr>
                                <td colSpan={6}></td>
                                <td className="table-left" colSpan={1}>Tax</td>
                                <td colSpan={1} align="right">7 %</td>
                                <td align="right">10000000</td>
                            </tr> */}
                            <tr>
                                <td colSpan={6}></td>
                                <td className="table-left" colSpan={2}>Total</td>
                                <td align="right">{gettTotal}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )

}


export default BalanceSheet
