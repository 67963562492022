import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { MenuItem, Select } from '@material-ui/core';
import { Card, CardBody, Table } from 'reactstrap';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function FinishedGoods() {
    const classes = useStyles();

    const [ProQuantity, setProQuantity] = useState();
    const [FabQuantity, setFabQuantity] = useState();

    const [GoodsList, setGoodsList] = useState();

    const [ProductList, setProductList] = useState([]);

    let [update, setUpdate] = useState(false)

    const onUpdateHandler = () => {
        setUpdate(!update)
    }

    useEffect(() => {
        axios.get('https://api.athind.com/api/product/list_products.php').then((response) => {
            console.log(response);
            setProductList(response.data.records);
        });
    }, []);

    const [ProValue, setProValue] = useState('');
    const [opens, setOpens] = useState(false);

    const handleChange = (e) => {
        setProValue(e.target.value);
    };

    const handleClose = () => {
        setOpens(false);
    };

    const handleOpen = () => {
        setOpens(true);
    };

    const [FabricList, setFabricList] = useState([]);

    useEffect(() => {
        axios.get('https://api.athind.com/api/stock/get_stock.php').then((response) => {
            console.log(response);
            setFabricList(response.data.records);
        });
    }, []);

  const [FabricQ, setFabricQ] = useState();
  const [StockId, setStockId] = useState();
  const updateFabQuantity = (FabricQ-FabQuantity);

    const getdata = (stock_id) => {
        const getingArray = FabricList.filter(newStock => {
          return newStock.stock_id === stock_id
        })
        // console.log(getingArray)
        setStockId(getingArray[0].stock_id);
        setFabricQ(getingArray[0].fabric_quantity);
      };

    const [FabValue, setFabValue] = useState('');
    const [openfab, setOpenFab] = useState(false);

    const handleChangeFab = (e) => {
        setFabValue(e.target.value);
    };

    const handleCloseFab = () => {
        setOpenFab(false);
    };

    const handleOpenFab = () => {
        setOpenFab(true);
    };

    const add_goods = (StockId) => {
        if (ProValue && FabValue && ProQuantity && FabQuantity) {
            axios.post('https://api.athind.com/api/stock/add_finished_goods.php', {
                product_name: ProValue,
                fabric_batch: FabValue,
                product_quantity: ProQuantity,
                raw_fabric_quantity: FabQuantity
            }).then(() => {
                axios.put('https://api.athind.com/api/stock/update_stock_quantity.php',{
                    stock_id: StockId,
                    fabric_quantity: updateFabQuantity
                })
                onUpdateHandler();
                toast.success("Finished Goods is Added!");
            });
        } else {
            toast.error("Please, fill all the fields!");
        }
    }

    const goodsdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/stock/get_finished_goods.php').then((response) => {
                console.log(response);
                setGoodsList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        goodsdata();
    }, [])


    const updategoodsdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/stock/get_finished_goods.php').then((response) => {
                console.log(response);
                setGoodsList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        updategoodsdata();
    }, [update])

    const deleteGoods = async (finished_id) => {
        await axios.delete('https://api.athind.com/api/stock/delete_goods.php/', { data: { finished_id: finished_id } }).then((response) => {
            setGoodsList(GoodsList.filter((val) => {
                return val.finished_id !== finished_id;
            }));
            toast.success("Finished Goods is Deleted!");
        });
    };

    return (
        <div>
            <div className="addnewp">Add Finished Goods</div>
            <ToastContainer />
            <div className="container cus">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="cost-select-heading">Select Product</div>
                        <Select id="demo-controlled-open-select" open={opens} onClose={handleClose}
                            onOpen={handleOpen} value={ProValue} onChange={handleChange}>
                            {ProductList.map((val, key) => {
                                return <MenuItem key={val.id} value={val.pro_title}>{val.pro_title}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-sm-3">
                        <div className="cost-select-heading">Select Fabric</div>
                        <Select id="demo-controlled-open-select" open={openfab} onClose={handleCloseFab}
                            onOpen={handleOpenFab} value={FabValue} onChange={handleChangeFab}>
                            {FabricList.map((val, key) => {
                                return <MenuItem key={val.stock_id} value={"FBR_0" + val.stock_id} onClick={() => { getdata(val.stock_id); }}>FBR_0{val.stock_id}</MenuItem>
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row cus">
                    <div className="col-sm-6">
                        <div className="partitle">Product Quantity <span className="psubtitle">(KG)</span></div>
                        <input type="number" className="form-control tfield" value={ProQuantity} onChange={(e) => { setProQuantity(e.target.value) }} />
                    </div>
                    <div className="col-sm-6">
                        <div className="partitle">Fabric Quantity <span className="psubtitle">(KG)</span></div>
                        <input type="number" className="form-control tfield" value={FabQuantity} onChange={(e) => { setFabQuantity(e.target.value) }} />
                    </div>
                    {/* <div className="col-sm-4">
                        <div className="partitle">Wastage</div>
                        <input type="number" className="form-control tfield" />
                    </div> */}
                </div>
                <button className="btn addpbtn btn-block" onClick={() => {add_goods(StockId)}}>Add New Finished Goods</button>
            </div>
            <div className="addnewp">Finished Goods Information</div>
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Product Name</th>
                                <th>Batch #</th>
                                <th>Product Quantity (KG)</th>
                                <th>Fabric Quantity (KG)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {GoodsList ? GoodsList.map((val) => {
                                return <tr key={val.finished_id}>
                                    <td>{val.finished_id}</td>
                                    <td>{val.product_name}</td>
                                    <td>{val.fabric_batch}</td>
                                    <td>{val.product_quantity}</td>
                                    <td>{val.raw_fabric_quantity}</td>
                                    <td className="cust-bttn">
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteGoods(val.finished_id); }} ><DeleteForeverOutlinedIcon /></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default FinishedGoods
