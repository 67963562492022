import React, { useState, useEffect } from 'react';
import '.././App.css';
import Axios from 'axios';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Accordion, Button } from 'react-bootstrap';
// import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
// import {ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MenuItem, Select } from '@material-ui/core';


const DcutCosting = () => {

  /* start collapse */
  // const [open,setOpen]=useState(false);
  const [costOpen, setCostOpen] = useState(false);
  /* end collapse */

  const [width, setWidth] = useState();
  const [lenght, setLenght] = useState();
  const [gusset, setBottomGusset] = useState();
  const [bagColor, setBagColor] = useState("");
  const [patteren, setPatteren] = useState("");
  const [gsm, setGsm] = useState();
  const [wastage, setWastage] = useState();
  const [fabricp, setFabricp] = useState();
  const [cmt, setCmt] = useState();
  const [packing, setPacking] = useState();
  const [printing, setPrinting] = useState();
  const [wastagerec, setWastagerec] = useState();
  const [overheads, setOverheads] = useState();
  const [profit, setProfit] = useState();
  const [saletax, setSaletax] = useState();
  const [incometax, setIncometax] = useState();
  const [commission, setCommission] = useState();
  const [topfold, setTopfold] = useState();
  const [loopColor, setLoopColor] = useState();
  const [loopLength, setLoopLength] = useState();
  const [loopWidth, setLoopWidth] = useState();
  const [gsm_loop, setGsmloop] = useState();

  const hand_len = loopLength * 2;

  const loop_cons = ((hand_len * loopWidth * gsm_loop) / 1550) / 1000;
  const round_loop_cons = Math.round(loop_cons * 10000) / 10000;

  const fabricreq = ((Number(lenght) + Number(topfold)) * 2) + Number(gusset);

  const fabricconsump = (((fabricreq * width * gsm) + (4 * 16)) / 1550 / 1000) + round_loop_cons;
  const round_fc = Math.round(fabricconsump * 10000) / 10000;

  // const final_ccons = (round_fc+round_loop_cons);

  const fabric_cost = round_fc * fabricp;
  const round_fcost = Math.round(fabric_cost * 100) / 100;

  const fabric_ckg = round_fcost / round_fc * 1;
  const round_fabric_ckg = Math.round(fabric_ckg * 1) / 1;

  const wastagef = (round_fcost * wastage) / 100;
  const round_wf = Math.round(wastagef * 100) / 100;

  const wastage_kg = (round_wf / round_fc * 1);
  const round_wkg = Math.round(wastage_kg * 1) / 1;

  const cmt_f = cmt * round_fc;
  const round_cmt_f = Math.round(cmt_f * 100) / 100;

  const cmt_kg = round_cmt_f / round_fc * 1;
  const round_cmt_kg = Math.round(cmt_kg * 1) / 1;

  const packing_f = packing * round_fc;
  const round_pf = Math.round(packing_f * 100) / 100;

  const packing_kg = round_pf / round_fc * 1;
  const round_packing_kg = Math.round(packing_kg * 1) / 1;

  const printing_p = printing * round_fc;
  const round_printing_p = Math.round(printing_p * 100) / 100;

  const printing_kg = round_printing_p / round_fc * 1;
  const round_printing_kg = Math.round(printing_kg * 1) / 1;

  const wastage_reckg = round_wkg * (-wastagerec / fabricp);
  const round_wrkg = Math.round(wastage_reckg * 100) / 100;

  const wastage_rp = round_wrkg * round_fc;
  const round_wastage_rp = Math.round(wastage_rp * 100) / 100;

  const overheads_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf) * overheads / 100;
  const round_overheads_p = Math.round(overheads_p * 100) / 100;

  const overheads_kg = round_overheads_p / round_fc * 1;
  const round_overheads_kg = Math.round(overheads_kg * 1) / 1;

  const profit_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p) * profit / 100;
  const round_profit_p = Math.round(profit_p * 100) / 100;

  const profit_kg = round_profit_p / round_fc * 1;
  const round_profit_kg = Math.round(profit_kg * 1) / 1;

  const saletax_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p) * saletax / 100;
  const round_saletax_p = Math.round(saletax_p * 100) / 100;

  const saletax_kg = round_saletax_p / round_fc * 1;
  const round_saletax_kg = Math.round(saletax_kg * 1) / 1;

  const incometax_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p) * incometax / 100;
  const round_incometax_p = Math.round(incometax_p * 100) / 100;

  const incometax_kg = round_incometax_p / round_fc * 1;
  const round_incometax_kg = Math.round(incometax_kg * 1) / 1;

  const commission_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p + round_incometax_p) * commission / 100;
  const round_commission_p = Math.round(commission_p * 100) / 100;

  const commission_kg = round_commission_p / round_fc * 1;
  const round_commission_kg = Math.round(commission_kg * 1) / 1;

  const selling_p_exclud = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_commission_p);
  const round_selling_p_exclud = Math.round(selling_p_exclud * 100) / 100;

  const selling_exclud_kg = (round_fabric_ckg + round_wkg + round_cmt_kg + round_packing_kg + round_printing_kg + round_wrkg + round_overheads_kg + round_profit_kg + round_commission_kg);

  const selling_p = (round_fcost + round_wf + round_wastage_rp + round_cmt_f + round_printing_p + round_pf + round_overheads_p + round_profit_p + round_saletax_p + round_incometax_p + round_commission_p);
  const round_selling_p = Math.round(selling_p * 100) / 100;

  const selling_kg = (round_fabric_ckg + round_wkg + round_cmt_kg + round_packing_kg + round_printing_kg + round_wrkg + round_overheads_kg + round_profit_kg + round_saletax_kg + round_incometax_kg + round_commission_kg);


  const wast_per = wastage / 100;
  const wast_mul = round_fc * wast_per;
  const wast_min = round_fc - wast_mul;
  const wast_div = 1 / wast_min;
  const round_qbag_kg = Math.round(wast_div * 1) / 1;


  const handleFocus = (event) => event.target.select();

  const [dcut, setDcutList] = useState([]);
  // const [dcutData, setDcutData] = useState([]);


  // const getProducts = () => {
  useEffect(() => {
    Axios.get('https://api.athind.com/api/product/dcut_list.php').then((response) => {
      console.log(response);
      setDcutList(response.data.records);
    });
  }, []);
  // };

  const getdata = (id) => {

    const getingArray = dcut.filter(dcutNew => {
      return dcutNew.id === id
    })

    console.log(getingArray)
    setWidth(getingArray[0].width)
    setLenght(getingArray[0].lenght)
    setBottomGusset(getingArray[0].gusset)
    setBagColor(getingArray[0].bag_color)
    setPatteren(getingArray[0].patteren)
    setGsm(getingArray[0].gsm)
    setTopfold(getingArray[0].topfold)
    setFabricp(getingArray[0].price)
    setGsmloop(getingArray[0].loop_gsm)
    setLoopWidth(getingArray[0].loop_width)
    setLoopLength(getingArray[0].loop_length)
    setLoopColor(getingArray[0].loop_color)
    setWastage(getingArray[0].wastage)
    setWastagerec(getingArray[0].wastagerecovery)
    setCmt(getingArray[0].cmt)
    setPrinting(getingArray[0].printing)
    setPacking(getingArray[0].packing)
    setOverheads(getingArray[0].overheads)
    setProfit(getingArray[0].profit)
    setSaletax(getingArray[0].saletax)
    setIncometax(getingArray[0].incometax)
    setCommission(getingArray[0].commission)


    // Axios.get(`http://localhost:3002/costbyid/${id}`).then((response)=>{
    //   console.log(response);
    //   // setDcutData(response.data);
    //   setWidth(response.data[0].width)
    //   setLenght(response.data[0].lenght)
    //   setBottomGusset(response.data[0].gusset)
    //   setGsm(response.data[0].gsm)
    //   setTopfold(response.data[0].topfold)
    //   setFabricp(response.data[0].price)
    //   setGsmloop(response.data[0].loop_gsm)
    //   setLoopWidth(response.data[0].loop_width)
    //   setLoopLength(response.data[0].loop_length)
    //   setWastage(response.data[0].wastage)
    //   setWastagerec(response.data[0].wastagerecovery)
    //   setCmt(response.data[0].cmt)
    //   setPrinting(response.data[0].printing)
    //   setPacking(response.data[0].packing)
    //   setOverheads(response.data[0].overheads)
    //   setProfit(response.data[0].profit)
    //   setSaletax(response.data[0].saletax)
    //   setIncometax(response.data[0].incometax)
    //   setCommission(response.data[0].commission)
    // });
  };

  const [product, setProducts] = useState('');
  const [opens, setOpens] = useState(false);

  const handleChange = (e) => {
    setProducts(e.target.value);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleOpen = () => {
    setOpens(true);
  };



  return (
    <div className="App">
      <div className="addnewp">Costing for D-Cut</div>
      {/* start Costing */}
      <div className="particulars">
        <div className="container">
          <div className="cost-select-heading">Please Select Product</div>
          <Select id="demo-controlled-open-select" open={opens} onClose={handleClose}
            onOpen={handleOpen} value={product} onChange={handleChange}>
            {dcut.map((val, key) => (
              <MenuItem key={val.id} value={val.id} onClick={() => { getdata(val.id); }}>{val.pro_title}</MenuItem>
            ))}
          </Select>

          {/* {dcutData.map((val, key) => { */}
          {/* return<> */}
          {/* <h1>{val.id}</h1> */}
          {/* start finished size row */}
          <div className="basic-row">
            <Accordion defaultActiveKey="1">
              <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                Finished Size <ExpandMoreOutlinedIcon className="basicbtn" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">gsm</div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={gsm} onChange={e => setGsm(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Width <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={width} onChange={e => setWidth(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Length <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={lenght} onChange={e => setLenght(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Bag Color</div>
                      <input type="text" className="form-control tfield" onFocus={handleFocus} value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Pattern</div>
                      <input type="text" className="form-control tfield" onFocus={handleFocus} value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Top Fold <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={topfold} onChange={e => setTopfold(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Bottom Gusset <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={gusset} onChange={e => setBottomGusset(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Fabric Price <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={fabricp} onChange={e => setFabricp(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Wastage <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={wastage} onChange={e => setWastage(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Wastage Recovery <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={wastagerec} onChange={e => setWastagerec(+e.target.value.replace(/[-]/, ""))} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">CMT <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={cmt} onChange={e => setCmt(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Printing <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={printing} onChange={e => setPrinting(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Packing <span className="psubtitle">(Per KG)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={packing} onChange={e => setPacking(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Over Heads <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={overheads} onChange={e => setOverheads(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Loop GSM</div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={gsm_loop} onChange={e => setGsmloop(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Loop Width <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={loopWidth} onChange={e => setLoopWidth(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Loop Length <span className="psubtitle">(Inches)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={loopLength} onChange={e => setLoopLength(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Loop Color</div>
                      <input type="text" className="form-control tfield" onFocus={handleFocus} value={loopColor} onChange={e => setLoopColor(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Profit <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={profit} onChange={e => setProfit(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Sale Tax <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={saletax} onChange={e => setSaletax(+e.target.value)} />
                    </div>
                    <div className="col-sm-4">
                      <div className="partitle">Income Tax <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={incometax} onChange={e => setIncometax(+e.target.value)} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="partitle">Commission <span className="psubtitle">(%)</span></div>
                      <input type="number" className="form-control tfield" onFocus={handleFocus} value={commission} onChange={e => setCommission(+e.target.value)} />
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
          {/* end finished size row */}
          <div className="price_row">
            <div className="row">
              <div className="col-sm-3">
                <div className="costing_div">Fabric Required</div>
                <input type="number" disabled className="form-control tfield" value={fabricreq} />
              </div>
              <div className="col-sm-3">
                <div className="costing_div">Fabric Consumption</div>
                <input type="number" disabled className="form-control tfield" value={round_fc} />
              </div>
              <div className="col-sm-3">
                <div className="costing_div">Quantity Bags</div>
                <input type="number" disabled className="form-control tfield" value={round_qbag_kg} />
              </div>
              <div className="col-sm-3">
                <div className="costing_div">Loop Consumption</div>
                <input type="number" disabled className="form-control tfield" value={round_loop_cons} />
              </div>
            </div>
          </div>

          <div className="basic-row">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle as={Button} onClick={() => setCostOpen(!costOpen)} className="btn ebasicbtn" variant="link" eventKey="1">
                Costing
                {!costOpen && <ExpandMoreOutlinedIcon className="basicbtn" />}
                {costOpen && <ExpandLessOutlinedIcon className="basicbtn" />}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Fabric Cost</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_fcost} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_fabric_ckg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">CMT</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_f} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_cmt_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Wastage</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wf} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wkg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Wastage Recovery</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_wastage_rp} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_wrkg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Packing</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_pf} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_packing_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Printing</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_printing_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Over Heads</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_overheads_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Profit</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_profit_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Sale Tax</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_saletax_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Income Tax</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_incometax_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Commission</div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={round_commission_kg} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Selling Price <span className="psubtitle">(Inclusive Tax)</span></div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row cost_row">
                    <div className="col-sm-3 cost-bord">
                      <div className="cost_title">Selling Price <span className="psubtitle">(Excluded Tax)</span></div>
                      <div className="row cost_out">
                        <div className="col-sm-6">
                          <div className="p_kg">per piece</div>
                          <input type="number" disabled className="form-control tfield" value={round_selling_p_exclud} />
                        </div>
                        <div className="col-sm-6">
                          <div className="p_kg">per kg</div>
                          <input type="number" disabled className="form-control tfield" value={selling_exclud_kg} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          {/* </> */}

          {/* })} */}

          {/* <button className="btn addpbtn btn-block" >add new product</button> */}
        </div>
      </div>
      {/* end Costing */}
    </div>
  );
}

export default DcutCosting;
