import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import '.././App.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import { MenuItem, Select, IconButton, InputLabel  } from '@material-ui/core';
import { Collapse, Accordion, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditProduct() {

    const history = useHistory();
    const { id } = useParams();

    /* start collapse */
    const [open, setOpen] = useState(false);
    /* end collapse */

    const [title, setTitle] = useState();
    const [type, setProType] = useState();
    const [width, setWidth] = useState();
    const [lenght, setLenght] = useState();
    const [gusset, setGusset] = useState();
    const [bagColor, setBagColor] = useState();
    const [patteren, setPatteren] = useState();
    const [printColr, setPrintColr] = useState('');
    const [gsm, setGsm] = useState();
    const [wastage, setWastage] = useState();
    const [price, setPrice] = useState();
    const [cmt, setCmt] = useState();
    const [packing, setPacking] = useState();
    const [printing, setPrinting] = useState();
    const [wastagerecovery, setWastagerecovery] = useState();
    const [overheads, setOverheads] = useState();
    const [profit, setProfit] = useState();
    const [saletax, setSaletax] = useState();
    const [incometax, setIncometax] = useState();
    const [commission, setCommission] = useState();
    const [topfold, setTopfold] = useState();
    const [loopColor, setLoopColor] = useState();
    const [loopLength, setLoopLength] = useState();
    const [loopWidth, setLoopWidth] = useState();
    const [loopgsm, setLoopGsm] = useState();
    const [piping, setPiping] = useState();
    const [Qbags, setQbags] = useState();

    const fetch_product_detail = async () => {
        await axios.get(`https://api.athind.com/api/product/wcut_data.php?id=${id}`).then((response) => {

            // console.log(response);
            const getingArray = (response.data);
            setTitle(getingArray.pro_title)
            setProType(getingArray.pro_type)
            setWidth(getingArray.width)
            setLenght(getingArray.lenght)
            setGusset(getingArray.gusset)
            setBagColor(getingArray.bag_color)
            setPatteren(getingArray.patteren)
            setPrintColr(getingArray.printing_color)
            setGsm(getingArray.gsm)
            setPrice(getingArray.price)
            setWastage(getingArray.wastage)
            setWastagerecovery(getingArray.wastagerecovery)
            setCmt(getingArray.cmt)
            setPrinting(getingArray.printing)
            setPacking(getingArray.packing)
            setOverheads(getingArray.overheads)
            setProfit(getingArray.profit)
            setSaletax(getingArray.saletax)
            setIncometax(getingArray.incometax)
            setCommission(getingArray.commission)
            setTopfold(getingArray.topfold)
            setLoopGsm(getingArray.loop_gsm)
            setLoopWidth(getingArray.loop_width)
            setLoopLength(getingArray.loop_length)
            setLoopColor(getingArray.loop_color)
            setPiping(getingArray.piping)
            setQbags(getingArray.quantity_bags)
        });
    }

    useEffect(() => {
        fetch_product_detail();
    }, []);

  const [opens, setOpens] = useState(false);

  const handleChange = (e) => {
    setPrintColr(e.target.value);
  };

  const handleClose = () => {
    setOpens(false);
  };

  const handleOpen = () => {
    setOpens(true);
  };

    function SubmitWcutButton() {
        if (title && type && gsm && price && bagColor && width && lenght && gusset && wastage
            && wastagerecovery && cmt && printing && packing && overheads && profit && saletax && incometax && commission) {

            return <button className="btn addpbtn btn-block" onClick={() => update_wcut_product(id)}>Update Product</button>
        } else {
            return <button className="btn addpbtn btn-block" disabled>Update Product</button>
        };
    };

    const update_wcut_product = async (id) => {
        await axios.put('https://api.athind.com/api/product/update.php', {
            id: id,
            pro_title: title,
            pro_type: type,
            gsm: gsm,
            price: price,
            bag_color: bagColor,
            patteren: patteren,
            printing_color: printColr,
            width: width,
            lenght: lenght,
            gusset: gusset,
            wastage: wastage,
            wastagerecovery: wastagerecovery,
            cmt: cmt,
            printing: printing,
            packing: packing,
            overheads: overheads,
            profit: profit,
            saletax: saletax,
            incometax: incometax,
            commission: commission
        }).then(() => {
            history.push("/pages/show-product/ShowProducts")
            toast.success("Product is Updated!");
        });
    };

    function SubmitDcutButton() {
        if (title && type && gsm && price && bagColor && width && lenght && gusset && topfold && wastage
            && wastagerecovery && cmt && printing && packing && overheads && profit && saletax && incometax && commission) {

            return <button className="btn addpbtn btn-block" onClick={() => update_dcut_product(id)}>Update Product</button>
        } else {
            return <button className="btn addpbtn btn-block" disabled>Update Product</button>
        };
    };

    const update_dcut_product = async (id) => {
        await axios.put('https://api.athind.com/api/product/update.php', {
            id: id,
            pro_title: title,
            pro_type: type,
            gsm: gsm,
            price: price,
            bag_color: bagColor,
            patteren: patteren,
            printing_color: printColr,
            width: width,
            lenght: lenght,
            gusset: gusset,
            topfold: topfold,
            loop_gsm: loopgsm,
            loop_width: loopWidth,
            loop_length: loopLength,
            loop_color: loopColor,
            wastage: wastage,
            wastagerecovery: wastagerecovery,
            cmt: cmt,
            printing: printing,
            packing: packing,
            overheads: overheads,
            profit: profit,
            saletax: saletax,
            incometax: incometax,
            commission: commission
        }).then(() => {
            history.push("/pages/show-product/ShowProducts")
            toast.success("Product is Updated!");
        });
    };

    function SubmitStitchedButton() {
        if (title && type && gsm && price && bagColor && width && lenght && gusset && topfold && loopWidth && loopLength && Qbags && piping && wastage
          && wastagerecovery && cmt && printing && packing && overheads && profit && saletax && incometax && commission) {
    
          return <button className="btn addpbtn btn-block" onClick={() => update_stitched_product(id)}>Update Product</button>
        } else {
          return <button className="btn addpbtn btn-block" disabled>Update Product</button>
        };
      };
    
      const update_stitched_product = async (id) => {
       await axios.post('https://api.athind.com/api/product/update.php', {
           id:id,
          pro_title: title,
          pro_type: type,
          gsm: gsm,
          price: price,
          bag_color: bagColor,
          patteren: patteren,
          printing_color: printColr,
          width: width,
          lenght: lenght,
          gusset: gusset,
          topfold: topfold,
          loop_width: loopWidth,
          loop_length: loopLength,
          loop_color: loopColor,
          quantity_bags: Qbags,
          piping: piping,
          wastage: wastage,
          wastagerecovery: wastagerecovery,
          cmt: cmt,
          printing: printing,
          packing: packing,
          overheads: overheads,
          profit: profit,
          saletax: saletax,
          incometax: incometax,
          commission: commission
        }).then(() => {
            history.push("/pages/show-product/ShowProducts")
          toast.success("Product is Updated!");
        });
      };

    return (
        <div>
            <div className="addnewp">Edit Product Information</div>
            <div className="">
                {type === "wcut" ? <div>
                    {/* start Particulars */}
                    <div className="particulars">
                        <div className="ptitle">title</div>
                        <div className="row">
                            <div className="col-sm-3">
                                <input type="text" className="form-control tfield" value={title} onChange={(e) => { setTitle(e.target.value); }} />
                            </div>
                        </div>
                        <div className="container form-div">
                            <div className="expandbutton">
                                {!open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandMoreOutlinedIcon className="expand" />expand all tabs</button>}
                            </div>
                            <div className="closebutton">
                                {open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandLessOutlinedIcon className="expand" />close all tabs</button>}
                            </div>

                            {/* start basic row */}
                            <div className="basic-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Basics <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    {/* {succ && <p className="errorproMsg">Product is inserted</p>} */}
                                    <ToastContainer />
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                        <div id="collapse">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="partitle">gsm</div>
                                                    <input type="number" className="form-control tfield" value={gsm} onChange={(e) => { setGsm(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Bag color</div>
                                                    <input type="text" className="form-control tfield" value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Pattern</div>
                                                    <input type="text" className="form-control tfield" value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                <div className="partitle">Printing Color</div>
                                                {/* <InputLabel id="demo-controlled-open-select-label">Pattern Color</InputLabel> */}
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    open={opens}
                                                    onClose={handleClose}
                                                    onOpen={handleOpen}
                                                    value={printColr}
                                                    onChange={handleChange}
                                                    className="tfield ptrnclr"
                                                >
                                                    <MenuItem value="">
                                                    <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="1 color">1 color</MenuItem>
                                                    <MenuItem value="2 color">2 color</MenuItem>
                                                    <MenuItem value="3 color">3 color</MenuItem>
                                                    <MenuItem value="4 color">4 color</MenuItem>
                                                </Select>
                                                </div>
                                            </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end basic row */}

                            {/* start size row */}
                            <div className="size-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Size <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">width <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={width} onChange={(e) => { setWidth(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">length <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={lenght} onChange={(e) => { setLenght(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Side gusset <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={gusset} onChange={(e) => { setGusset(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end size row */}

                            {/* start price row */}
                            <div className="price-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Pricing <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Fabric price  <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={price} onChange={(e) => { setPrice(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastage} onChange={(e) => { setWastage(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage recovery <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastagerecovery} onChange={(e) => { setWastagerecovery(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">cmt <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={cmt} onChange={(e) => { setCmt(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">printing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={printing} onChange={(e) => { setPrinting(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">packing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={packing} onChange={(e) => { setPacking(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">over heads <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={overheads} onChange={(e) => { setOverheads(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">profit <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={profit} onChange={(e) => { setProfit(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">sale tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={saletax} onChange={(e) => { setSaletax(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">income tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={incometax} onChange={(e) => { setIncometax(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">commission <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={commission} onChange={(e) => { setCommission(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end price row */}
                            <SubmitWcutButton />
                            {/* <button className="btn addpbtn btn-block" onClick={handleSubmit}>Reset Fields</button> */}
                        </div>
                    </div>
                    {/* end Particulars */}
                </div> : null}

                {type === "dcut" ? <div>
                    {/* start Particulars */}
                    <div className="particulars">
                        <div className="ptitle">title</div>
                        <div className="row">
                            <div className="col-sm-3">
                                <input type="text" className="form-control tfield" value={title} onChange={(e) => { setTitle(e.target.value); }} />
                            </div>
                        </div>
                        <div className="container form-div">
                            <div className="expandbutton">
                                {!open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandMoreOutlinedIcon className="expand" />expand all tabs</button>}
                            </div>
                            <div className="closebutton">
                                {open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandLessOutlinedIcon className="expand" />close all tabs</button>}
                            </div>

                            {/* start basic row */}
                            <div className="basic-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Basics <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    {/* {succ && <p className="errorproMsg">Product is inserted</p>} */}
                                    <ToastContainer />
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                            <div className="row">

                                                <div className="col-sm-4">
                                                    <div className="partitle">gsm</div>
                                                    <input type="number" className="form-control tfield" value={gsm} onChange={(e) => { setGsm(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Bag Color</div>
                                                    <input type="text" className="form-control tfield" value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Pattern</div>
                                                    <input type="text" className="form-control tfield" value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                <div className="partitle">Printing Color</div>
                                                {/* <InputLabel id="demo-controlled-open-select-label">Pattern Color</InputLabel> */}
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    open={opens}
                                                    onClose={handleClose}
                                                    onOpen={handleOpen}
                                                    value={printColr}
                                                    onChange={handleChange}
                                                    className="tfield ptrnclr"
                                                >
                                                    <MenuItem value="">
                                                    <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="1 color">1 color</MenuItem>
                                                    <MenuItem value="2 color">2 color</MenuItem>
                                                    <MenuItem value="3 color">3 color</MenuItem>
                                                    <MenuItem value="4 color">4 color</MenuItem>
                                                </Select>
                                                </div>
                                            </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end basic row */}

                            {/* start size row */}
                            <div className="size-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Size <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">width <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={width} onChange={(e) => { setWidth(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">length <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={lenght} onChange={(e) => { setLenght(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Bottom gusset <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={gusset} onChange={(e) => { setGusset(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">top fold <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={topfold} onChange={(e) => { setTopfold(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end size row */}

                            {/* start loop row */}
                            <div className="basic-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Loop <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    {/* {succ && <p className="errorproMsg">Product is inserted</p>} */}
                                    <ToastContainer />
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop gsm</div>
                                                        <input type="number" className="form-control tfield" value={loopgsm} onChange={(e) => { setLoopGsm(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Width <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={loopWidth} onChange={(e) => { setLoopWidth(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Length <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={loopLength} onChange={(e) => { setLoopLength(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Color</div>
                                                        <input type="text" className="form-control tfield" value={loopColor} onChange={(e) => { setLoopColor(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end loop row */}

                            {/* start price row */}
                            <div className="price-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Pricing <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Fabric Price <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={price} onChange={(e) => { setPrice(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastage} onChange={(e) => { setWastage(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage recovery <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastagerecovery} onChange={(e) => { setWastagerecovery(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">cmt <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={cmt} onChange={(e) => { setCmt(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">printing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={printing} onChange={(e) => { setPrinting(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">packing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={packing} onChange={(e) => { setPacking(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">over heads <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={overheads} onChange={(e) => { setOverheads(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">profit <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={profit} onChange={(e) => { setProfit(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">sale tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={saletax} onChange={(e) => { setSaletax(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">income tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={incometax} onChange={(e) => { setIncometax(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">commission <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={commission} onChange={(e) => { setCommission(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end price row */}
                            {/* <button className="btn addpbtn btn-block" onClick={addProduct}>add new product</button> */}
                            <SubmitDcutButton />
                            {/* <button className="btn addpbtn btn-block" onClick={handleSubmit}>Reset Fields</button> */}
                        </div>
                    </div>
                    {/* end Particulars */}
                </div> : null}

                {type === "stitched" ? <div>
                    {/* start Particulars */}
                    <div className="particulars">
                        <div className="ptitle">title</div>
                        <div className="row">
                            <div className="col-sm-3">
                                <input type="text" className="form-control tfield" value={title} onChange={(e) => { setTitle(e.target.value); }} />
                            </div>
                        </div>
                        <div className="container form-div">
                            <div className="expandbutton">
                                {!open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandMoreOutlinedIcon className="expand" />expand all tabs</button>}
                            </div>
                            <div className="closebutton">
                                {open && <button className="btn expandbtn" onClick={() => setOpen(!open)} aria-controls="collapse"><ExpandLessOutlinedIcon className="expand" />close all tabs</button>}
                            </div>

                            {/* start basic row */}
                            <div className="basic-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Basics <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    {/* {succ && <p className="errorproMsg">Product is inserted</p>} */}
                                    <ToastContainer />
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                        <div id="collapse">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="partitle">gsm</div>
                                                    <input type="number" className="form-control tfield" value={gsm} onChange={(e) => { setGsm(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Bag Color</div>
                                                    <input type="text" className="form-control tfield" value={bagColor} onChange={(e) => { setBagColor(e.target.value); }} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="partitle">Pattern</div>
                                                    <input type="text" className="form-control tfield" value={patteren} onChange={(e) => { setPatteren(e.target.value); }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                <div className="partitle">Printing Color</div>
                                                {/* <InputLabel id="demo-controlled-open-select-label">Pattern Color</InputLabel> */}
                                                <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id="demo-controlled-open-select"
                                                    open={opens}
                                                    onClose={handleClose}
                                                    onOpen={handleOpen}
                                                    value={printColr}
                                                    onChange={handleChange}
                                                    className="tfield ptrnclr"
                                                >
                                                    <MenuItem value="">
                                                    <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="1 color">1 color</MenuItem>
                                                    <MenuItem value="2 color">2 color</MenuItem>
                                                    <MenuItem value="3 color">3 color</MenuItem>
                                                    <MenuItem value="4 color">4 color</MenuItem>
                                                </Select>
                                                </div>
                                            </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end basic row */}

                            {/* start size row */}
                            <div className="size-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Size <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">width <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={width} onChange={(e) => { setWidth(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">length <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={lenght} onChange={(e) => { setLenght(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">gusset <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={gusset} onChange={(e) => { setGusset(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">top fold <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={topfold} onChange={(e) => { setTopfold(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">piping <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={piping} onChange={(e) => { setPiping(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Quantity Bags <span className="psubtitle">(Pcs)</span></div>
                                                        <input type="number" className="form-control tfield" value={Qbags} onChange={(e) => { setQbags(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end size row */}

                            {/* start loop row */}
                            <div className="size-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Loop <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Width <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={loopWidth} onChange={(e) => { setLoopWidth(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Length <span className="psubtitle">(Inches)</span></div>
                                                        <input type="number" className="form-control tfield" value={loopLength} onChange={(e) => { setLoopLength(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">Loop Color</div>
                                                        <input type="text" className="form-control tfield" value={loopColor} onChange={(e) => { setLoopColor(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end loop row */}


                            {/* start price row */}
                            <div className="price-row">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Toggle as={Button} className="btn ebasicbtn" variant="link" eventKey="1">
                                        Pricing <ExpandMoreOutlinedIcon className="basicbtn" />
                                    </Accordion.Toggle>
                                    <Collapse in={open}>
                                        <Accordion.Collapse eventKey="1">
                                            <div id="collapse">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle"> Fabric Price <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={price} onChange={(e) => { setPrice(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastage} onChange={(e) => { setWastage(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">wastage recovery <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={wastagerecovery} onChange={(e) => { setWastagerecovery(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">cmt <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={cmt} onChange={(e) => { setCmt(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">printing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={printing} onChange={(e) => { setPrinting(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">packing <span className="psubtitle">(Per KG)</span></div>
                                                        <input type="number" className="form-control tfield" value={packing} onChange={(e) => { setPacking(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">over heads <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={overheads} onChange={(e) => { setOverheads(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">profit <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={profit} onChange={(e) => { setProfit(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">sale tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={saletax} onChange={(e) => { setSaletax(e.target.value); }} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="partitle">income tax <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={incometax} onChange={(e) => { setIncometax(e.target.value); }} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="partitle">commission <span className="psubtitle">(%)</span></div>
                                                        <input type="number" className="form-control tfield" value={commission} onChange={(e) => { setCommission(e.target.value); }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </Collapse>
                                </Accordion>
                            </div>
                            {/* end price row */}
                            {/* <button className="btn addpbtn btn-block" onClick={addProduct}>add new product</button> */}
                            <SubmitStitchedButton />
                            {/* <button className="btn addpbtn btn-block" onClick={handleSubmit}>Reset Fields</button> */}
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    )
}

export default EditProduct
