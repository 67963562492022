import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Card } from '@material-ui/core';

export default class LogIn extends Component {
    constructor(props) {
        super(props)
        const token = localStorage.getItem("token")
        let loggedIn = true
        if (token == null) {
            loggedIn = false
        }
        this.state = {
            username: '',
            password: '',
            loggedIn
        }
        this.onChange = this.onChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    submitForm(e) {
        e.preventDefault()
        const { username, password } = this.state
        // login magic
        if (username && password) {
            if (username === "ATH" && password === "ATH786!") {
                localStorage.setItem("token", "test")
                this.setState({
                    loggedIn: true
                })
            } else {
                toast.error("Invalid username & password!");
            }
        } else {
            toast.error("Fields are empty!");
        }
    }

    render() {
        if (this.state.loggedIn) {
            return <Redirect to="home" />
        }
        return (
            <div className="container">
                <ToastContainer />
                <Form onSubmit={this.submitForm}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title><h1>ATH Login</h1></Card.Title>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="username" name="username" value={this.state.username} onChange={this.onChange} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="password" name="password" value={this.state.password} onChange={this.onChange} />
                            </div>
                            <div className="form-group">
                                <Button type="submit">Sign in</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        )
    }
}
