import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Card, CardBody, Table } from 'reactstrap';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function SendFinishedGoods() {
    const classes = useStyles();

    const [ProductList, setProductList] = useState([]);
    const [BatchNo, setBatchNo] = useState();
    const [ProQuantity, setProQuantity] = useState();

    useEffect(() => {
        axios.get('https://api.athind.com/api/stock/get_finished_goods.php').then((response) => {
            console.log(response);
            setProductList(response.data.records);
        });
    }, []);

    const [ProValue, setProValue] = useState('');
    const [opens, setOpens] = useState(false);

    const handleChange = (e) => {
        setProValue(e.target.value);
    };

    const handleClose = () => {
        setOpens(false);
    };

    const handleOpen = () => {
        setOpens(true);
    };

    const getProBatch = (finished_id) => {
        const getingArray = ProductList.filter(newlist => {
            return newlist.finished_id === finished_id
        })
        // console.log(getingArray)
        setBatchNo(getingArray[0].fabric_batch)
        setProQuantity(getingArray[0].product_quantity)
    };

    const [city, setCity] = useState('');
    const [openCity, setOpenCity] = useState(false);

    const handleChangeCity = (e) => {
        setCity(e.target.value);
    };

    const handleCloseCity = () => {
        setOpenCity(false);
    };

    const handleOpenCity = () => {
        setOpenCity(true);
    };

    const add_lhr_goods = () => {
        if (ProValue && city && ProQuantity) {
            axios.post('https://api.athind.com/api/stock/lhr_finished_goods.php', {
                product_name: ProValue,
                batch_no: BatchNo,
                city: city,
                product_quantity: ProQuantity
            }).then(() => {
                toast.success("Finished goods is Added to Lahore!");
            });
        } else {
            toast.error("Please, fill all the fields!");
        }
    }

    const [Lhrgoods, setLhrGoodsList] = useState()

    const goodsdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/stock/get_lhr_finished_goods.php').then((response) => {
                console.log(response);
                setLhrGoodsList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        goodsdata();
    }, [])

    return (
        <div>
            <div className="addnewp">Send Finished Goods</div>
            <ToastContainer />
            <div className="container cus">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="cost-select-heading">Select Product</div>
                        <Select id="demo-controlled-open-select" open={opens} onClose={handleClose}
                            onOpen={handleOpen} value={ProValue} onChange={handleChange}>
                            {ProductList.map((val, key) => {
                                return <MenuItem key={val.finished_id} value={val.product_name} onClick={() => { getProBatch(val.finished_id); }}>{val.product_name +" / "+ val.fabric_batch}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="col-sm-3">
                        <div className="cost-select-heading">Select City</div>
                        <Select id="demo-controlled-open-select" open={openCity} onClose={handleCloseCity}
                            onOpen={handleOpenCity} value={city} onChange={handleChangeCity}>
                            <MenuItem value="Lahore">Lahore</MenuItem>
                            <MenuItem value="Karachi">Karachi</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className="row cus">
                    <div className="col-sm-6">
                        <div className="partitle">Batch No.</div>
                        <input type="text" className="form-control tfield" value={BatchNo} disabled />
                    </div>
                    <div className="col-sm-6">
                        <div className="partitle">Product Quantity <span className="psubtitle">(KG)</span></div>
                        <input type="number" className="form-control tfield" value={ProQuantity} onChange={(e) => { setProQuantity(e.target.value) }} />
                    </div>
                    <button className="btn addpbtn btn-block" onClick={add_lhr_goods}>Send Finished Goods</button>
                </div>
            </div>
            <div className="addnewp">Lahore Finished Goods Information</div>
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Product Name</th>
                                <th>Batch #</th>
                                <th>City</th>
                                <th>Product Quantity (KG)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Lhrgoods ? Lhrgoods.map((val) => {
                                return <tr key={val.id}>
                                    <td>{val.id}</td>
                                    <td>{val.product_name}</td>
                                    <td>{val.batch_no}</td>
                                    <td>{val.city}</td>
                                    <td>{val.product_quantity}</td>
                                    <td>
                                        <button className="btn btn-danger eddl delpro" ><DeleteForeverOutlinedIcon /></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default SendFinishedGoods
