import React, { useState, useEffect } from 'react';
import AddStock from './AddStock'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Card, CardBody, Table } from 'reactstrap';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function FabricStock() {
    const classes = useStyles();

    const [stockList, setStockList] = useState(null);

    const [show, setShow] = useState(false);
    let [update, setUpdate] = useState(false)

    const onUpdateHandler = () => {
        setUpdate(!update)
    }

    const modalVisibility = () => {
        setShow(!show)
    }

    const stockdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/stock/get_stock.php').then((response) => {
                console.log(response);
                setStockList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        stockdata();
    }, [])

    const stocksdata = async () => {
        try {
            await axios.get('https://api.athind.com/api/stock/get_stock.php').then((response) => {
                console.log(response);
                setStockList(response.data.records);
            });
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        stocksdata();
    }, [update])

    const deleteStock = async (stock_id) => {
        await axios.delete('https://api.athind.com/api/stock/delete_stock.php/', { data: { stock_id: stock_id } }).then((response) => {
            setStockList(stockList.filter((val) => {
                return val.stock_id !== stock_id;
            }));
            toast.success("Stock is Deleted!");
        });
    };

    return (
        <div>
            <div className="container cus">
                <Button variant="primary" onClick={modalVisibility}>
                    Add New Stock
                </Button>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-40w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddStock
                        updateHandler={onUpdateHandler}
                        modal_visibility={modalVisibility}
                    />
                </Modal.Body>
            </Modal>
            <div className="addnewp">Stock Information</div>
            <ToastContainer />
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Batch #</th>
                                <th>Fabric Type</th>
                                <th>Fabric Quantity (kg)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stockList ? stockList.map((val) => {
                                return <tr key={val.stock_id}>
                                    <td>{val.stock_id}</td>
                                    <td>{val.sender_name}</td>
                                    <td>FBR_0{val.stock_id}</td>
                                    <td>{val.fabric_type}</td>
                                    <td>{val.fabric_quantity}</td>
                                    <td className="cust-bttn">
                                        <button className="btn btn-danger eddl delpro" onClick={() => { deleteStock(val.stock_id); }}><DeleteForeverOutlinedIcon /></button>
                                    </td>
                                </tr>
                            }) : <Backdrop className={classes.backdrop} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    )
}

export default FabricStock
