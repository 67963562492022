import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LogIn from './components/Login/LogIn';
import Logout from './components/Login/Logout';
// import DashboardLayout from './layouts/DashboardLayout';
import HomeContainer from './containers/HomeContainer'
import './vibe/scss/styles.scss';

function App() {

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={LogIn} />
        <Route path="/home" component={HomeContainer} />
        <Route path="/logout" component={Logout} />
        <Route path="/pages/stock/FabricStock" component={HomeContainer} />
        <Route path="/pages/stock/FinishedGoods" component={HomeContainer} />
        <Route path="/pages/stock/SendFinishedGoods" component={HomeContainer} />
        <Route path="/pages/add-product/Wcut" component={HomeContainer} />
        <Route path="/pages/add-product/Dcut" component={HomeContainer} />
        <Route path="/pages/add-product/Stitched" component={HomeContainer} />
        <Route path="/pages/show-product/ShowProducts" component={HomeContainer} />
        <Route path="/pages/show-product/EditProduct/:id" component={HomeContainer} />
        <Route path="/pages/customer-profile/AddCustomer" component={HomeContainer} />
        <Route path="/pages/customer-profile/Customers" component={HomeContainer} />
        <Route path="/pages/customer-profile/EditCustomer/:customer_id" component={HomeContainer} />
        <Route path="/pages/customer-profile/AddContact/:customer_id" component={HomeContainer} />
        <Route path="/pages/customer-profile/CustomerProfile/:customer_id" component={HomeContainer} />
        <Route path="/pages/customer-profile/EditContact/:contact_id" component={HomeContainer} />
        <Route path="/pages/costing/WcutCosting" component={HomeContainer} />
        <Route path="/pages/costing/DcutCosting" component={HomeContainer} />
        <Route path="/pages/costing/StitchedCosting" component={HomeContainer} />
        <Route path="/pages/accounts/BalanceSheet" component={HomeContainer} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;